import React, { useState, useMemo, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Paper } from "@mui/material";
import jwtDecode from "jwt-decode";
import toast, { Toaster } from "react-hot-toast";

import "./App.css";
import Home from "./views/Home/Home";
// import Create from "./views/Create/Create";
import Library from "./views/Library/Library";
import BottomBarPlayer from "./components/Player/Player";
import Login from "./views/Login/Login";
import FilterByTag from "./views/FilterByTag/FilterByTag";
import Collection from "./views/Collection/Collection";
import Favorites from "./views/Favorites/Favorites";
import SearchResults from "./views/SearchResults/SearchResults";
import Playlist from "./views/Playlist/Playlist";
import Privacy from "./views/Privacy/Privacy";
import Setup from "./views/Install/Setup";

import PlayerContext from "./context/playerContext";
import SearchContext from "./context/searchContext";
import AuthContext from "./context/authContext";

import NavBar from "./components/NavBar/NavBar";

import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
  isValidToken,
  removeRefreshToken,
} from "./api/token";
import { /*getNewAccessToken,*/ logoutUser, verifyActivity } from "./api/auth";
import { removeUserData, saveUserData } from "./api/user";
import { getFavoritesByEmail } from "./api/favorites";
import { getMyPlaylists } from "./api/playlists";
import { getAllTitles } from "./api/audios";
import { sendLogoutRequest, sendCurrentLocation } from "./utils/mobile";
import { notifyBugsnag } from "./utils/BugsnagService";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
      contrast: "#248BFF",
      dark: "#080b28",
      softDark: "#161a35",
    },
  },
});

export const notify = (message) => {
  toast(message, {
    style: {
      background: "#fff",
      color: "#080b28",
    },
  });
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  location && sendCurrentLocation(location.pathname);
  const [showPlayer, setShowPlayer] = useState(false);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [queue, setQueue] = useState([]);
  const [playingACollection, setPlayingACollection] = useState(false);
  const [collection, setCollection] = useState(null);
  const [collectionId, setCollectionId] = useState("");

  const putShowPlayer = (showPlayer) => {
    setShowPlayer(showPlayer);
  };

  const putAudio = (audio) => {
    setAudio(audio);
  };

  const putIsPlaying = (isPlaying) => {
    setIsPlaying(isPlaying);
  };

  const putQueue = (queue) => {
    setQueue(queue);
  };

  const putShowSearch = (showSearch) => {
    setShowSearch(showSearch);
  };

  const putPlayingACollection = (playingACollection) => {
    setPlayingACollection(playingACollection);
  };

  const putCollection = (collection) => {
    setCollection(collection);
  };

  const putCollectionId = (collectionId) => {
    setCollectionId(collectionId);
  };

  const playerData = useMemo(
    () => ({
      showPlayer: showPlayer,
      audio: audio,
      isPlaying: isPlaying,
      queue: queue,
      setShowPlayer: putShowPlayer,
      setAudio: putAudio,
      setIsPlaying: putIsPlaying,
      setQueue: putQueue,
      playingACollection: playingACollection,
      setPlayingACollection: putPlayingACollection,
      collection: collection,
      setCollection: putCollection,
      collectionId: collectionId,
      setCollectionId: putCollectionId,
    }),
    [
      showPlayer,
      audio,
      isPlaying,
      queue,
      playingACollection,
      collection,
      collectionId,
    ]
  );

  // SEARCH CONTEXT
  const [showSearch, setShowSearch] = useState(false);
  const searchData = useMemo(
    () => ({
      showSearch: showSearch,
      setShowSearch: putShowSearch,
    }),
    [showSearch]
  );

  // AUTH CONTEXT
  const [auth, setAuth] = useState(undefined);
  const [reloadUser, setReloadUser] = useState(false);

  const navigateAway = () => {
    const ecxept = ["/login", "/privacy", "/setup"];
    if (!ecxept.includes(location.pathname)) {
      navigate("/login");
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (auth) {
      verifyActivity().then((response) => {
        if (response === 200) {
          console.log("user is active");
        } else {
          setAuth(null);
          removeAccessToken();
          removeRefreshToken();
          removeUserData();
          navigateAway();
        }
      });
    }
  }, [auth]);

  useEffect(() => {
    const token = getAccessToken();

    if (!token) {
      setAuth(null);
      navigateAway();
    } else if (token && !isValidToken(token)) {
      setAuth(null);
      removeAccessToken();
      navigateAway();
    } else {
      setAuth({
        token,
        username: jwtDecode(token).username,
        email: jwtDecode(token).email,
      });

      getAllTitles();
      getFavoritesByEmail();
      getMyPlaylists();
    }
  }, [reloadUser]);

  const login = (token) => {
    setAccessToken(token);
    setAuth({
      token,
      username: jwtDecode(token).username,
      email: jwtDecode(token).email,
    });
    saveUserData({
      username: jwtDecode(token).username,
      email: jwtDecode(token).email,
    });
    getAllTitles();
    getFavoritesByEmail();
    getMyPlaylists();
  };

  const logout = async () => {
    if (auth) {
      try {
        await logoutUser();
        removeAccessToken();
        removeRefreshToken();
        removeUserData();
        setIsPlaying(false);
        setAuth(null);
        sendLogoutRequest();
        navigate("/login");
      } catch (error) {
        notifyBugsnag(error);
        console.log(error);
      }
    }
  };

  const authData = useMemo(
    () => ({
      auth: auth,
      login: login,
      logout: logout,
      setReloadUser: setReloadUser,
    }),
    [auth]
  );

  useEffect(() => {
    localStorage.removeItem("home");
    localStorage.removeItem("lastHomeRefresh");
  }, []);

  window.addEventListener("online", () => {
    const audioPlayer = document.querySelector("audio");
    if (audioPlayer) {
      audioPlayer.play();
    }
  });

  return auth ? (
    <AuthContext.Provider value={authData}>
      <PlayerContext.Provider value={playerData}>
        <SearchContext.Provider value={searchData}>
          <ThemeProvider theme={darkTheme}>
            <div className="App">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/listen" element={<Home />} />
                {/* <Route path="/create" element={<Create />} /> */}
                <Route
                  path="/library"
                  element={<Library showPlayer={showPlayer} />}
                />
                <Route path="/collection/:id" element={<Collection />} />
                <Route path="/tag/:tag" element={<FilterByTag />} />
                <Route path="/favorites" element={<Favorites />} />
                <Route path="/search" element={<SearchResults />} />
                <Route path="/playlist/:id" element={<Playlist />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Home />} />
              </Routes>
              <BottomBarPlayer
                showPlayer={showPlayer}
                setShowPlayer={setShowPlayer}
                audio={audio}
                setAudio={setAudio}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                queue={queue}
                setQueue={setQueue}
                playingACollection={playingACollection}
                setPlayingACollection={setPlayingACollection}
                collection={collection}
                setCollection={setCollection}
                collectionId={collectionId}
                setCollectionId={setCollectionId}
              />
            </div>
            <Paper
              sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
              elevation={3}
              className="BottomNavBar"
            >
              <NavBar />
            </Paper>
            <Toaster />
          </ThemeProvider>
        </SearchContext.Provider>
      </PlayerContext.Provider>
    </AuthContext.Provider>
  ) : (
    <AuthContext.Provider value={authData}>
      <ThemeProvider theme={darkTheme}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/setup" element={<Setup />} />
        </Routes>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
