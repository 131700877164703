import React, { useState, useEffect } from "react";
import "./Tags.css";
import Skeleton from "@mui/material/Skeleton";

import { getTags } from "../../api/tags";
import { Capitalize } from "../../utils/strings";
import { Link } from "react-router-dom";
import { notifyBugsnag } from "../../utils/BugsnagService";


export default function Tags() {
  const [tags, setTags] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTags()
      .then((data) => {
        setTags(data);
        setLoading(false);
      })
      .catch((error) => {
        notifyBugsnag(error);
        return null;
      });
  }, []);

  return (
    <div className="tags Horizontal">
      {loading ? (
        tags.map((tag, index) => {
          return (
            <div className="skeleton" key={index}>
              <Skeleton
                sx={{
                  bgcolor: "blueGrey.500",
                  marginBottom: "10px",
                  borderRadius: "30px",
                }}
                variant="rectangular"
                width={100}
                height={40}
                animation="wave"
              />
            </div>
          );
        })
      ) : (
        <>
          <div className="tags Horizontal">
            {tags.map((tag, index) => {
              return (
                <Link to={"/tag/" + tag.name} className="tag" key={index}>
                  <p>{Capitalize(tag.name)}</p>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
