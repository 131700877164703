import React from "react";
import "./FavoriteCollections.css";
import LazyLoad from "react-lazy-load";
import { useNavigate } from "react-router-dom";

import { getAuthorsString } from "../../api/audios";

export default function FavoriteCollections(props) {
  const { collections } = props;

  const navigate = useNavigate();

  const getCollectionType = (type) => {
    switch (type) {
      case 1:
        return "Álbum";
      case 2:
        return "Podcast";
      case 3:
        return "Serie";
      default:
        return "Colección";
    }
  };

  return (
    <div>
      {collections.length > 0 ? (
        <>
          <div className="favorite-collections-container">
            {collections.map((collection) => (
              <div
                className="favorite-collections-item"
                key={collection.id}
                onClick={() => navigate(`/collection/${collection.id}`)}
              >
                <LazyLoad>
                  <img src={collection.thumbnail} alt={collection.title} />
                </LazyLoad>
                <div className="favorite-collections-item-info">
                  <h3>{collection.title}</h3>
                  <p>
                    {getAuthorsString(collection.authors)} -{" "}
                    {getCollectionType(collection.type)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}
