import { getAuthorsString } from "../api/audios";
import { notify } from "../App";
import { notifyBugsnag } from "../utils/BugsnagService";

export const displayControlsOnLockScreen = (audio) => {
  if ("mediaSession" in navigator) {
    const player = document.querySelector("audio");

    navigator.mediaSession.metadata = new MediaMetadata({
      title: audio.title,
      artist: getAuthorsString(audio.authors),
      artwork: [
        {
          src: audio.thumbnail,
          sizes: "256x256",
          type: "image/jpeg",
        },
        {
          src: audio.thumbnail,
          sizes: "512x512",
          type: "image/jpeg",
        },
      ],
    });

    navigator.mediaSession.setActionHandler("play", () => player.play());
    navigator.mediaSession.setActionHandler("pause", () => player.pause());
    navigator.mediaSession.setActionHandler("seekbackward", (details) => {
      const skipTime = details.seekOffset || 1;
      player.currentTime = Math.max(player.currentTime - skipTime, 0);
    });

    navigator.mediaSession.setActionHandler("seekforward", (details) => {
      const skipTime = details.seekOffset || 1;
      player.currentTime = Math.min(
        player.currentTime + skipTime,
        player.duration
      );
    });

    navigator.mediaSession.setActionHandler("seekto", (details) => {
      if (details.fastSeek && "fastSeek" in player) {
        player.fastSeek(details.seekTime);
        return;
      }
      player.currentTime = details.seekTime;
    });

    navigator.mediaSession.setActionHandler("previoustrack", () => {
      player.currentTime = 0;
    });
  }
};

export const share = (item, type) => {
  let target = `${window.location.origin}/${type}/${item.id}`;
  if (type === "audio") {
    target = `${window.location.origin}/listen?t=${item.id}`;
  }

  const shareData = {
    title: item.title,
    text: "Escúchalo en Rhema",
    url: target,
  };

  try {
    navigator.share(shareData);
  } catch (err) {
    navigator.clipboard.writeText(shareData.url);
    notify("Enlace copiado al portapapeles", "success");
    notifyBugsnag(err);
  }
};

export const registrationConfig = {
  onUpdate: () => {
    notify(
      "Hay una actualización disponible, para descargarla cierra todas las pestañas donde estés usando Rhema"
    );
  },
};

export const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};
