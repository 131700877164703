import { Box, Dialog } from "@mui/material";
import React from "react";

export default function Speed(props) {
  const { speed, handleSpeed, setOpen, open } = props;
  const speeds = [
    {
      value: 1.0,
      label: "Normal",
    },
    {
      value: 1.25,
      label: "1.25x",
    },
    {
      value: 1.5,
      label: "1.5x",
    },
  ];

  return (
    <Dialog open={open} onClose={() => setOpen(false)} sx={{ zIndex: 2000 }}>
      <Box
        sx={{
          width: "35vw",
          height: "auto",
          backgroundColor: "primary.dark",
          padding: "20px",
        }}
      >
        {speeds.map((speedItem) => (
          <Box
            className="SpeedItem"
            onClick={() => handleSpeed(speedItem.value)}
            sx={{
              padding: "10px",

              backgroundColor:
                speedItem.value === speed ? "primary.main" : "primary.dark",
              color:
                speedItem.value === speed
                  ? "primary.contrastText"
                  : "primary.main",
              borderRadius: "5px",
              marginBottom: "10px",
              cursor: "pointer",
              transition: "0.5s ease-in-out",
            }}
          >
            {speedItem.label}
          </Box>
        ))}
      </Box>
    </Dialog>
  );
}
