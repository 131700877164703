import React from "react";
import "./SortChips.css";

export default function SortChips(props) {
  const { sort, callback } = props;
  const sortChips = [
    {
      label: "Más recientes",
      value: "newest",
    },
    {
      label: "Más antiguos",
      value: "oldest",
    },
  ];

  return (
    <div className="SortChips">
      {sortChips.map((chip) => (
        <div
          className={sort === chip.value ? "chip active" : "chip"}
          onClick={() => callback(chip.value)}
          key={chip.value}
        >
          {chip.label}
        </div>
      ))}
    </div>
  );
}
