import axios from "axios";

import { config } from "../config";
import { getAccessToken } from "./token";

export const getSearchResults = (query) => {
  return axios.get(config.coreApiUrl + "/search?query=" + query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};
