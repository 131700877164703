import React, { useEffect, useState } from "react";
import "./Player.css";
import { Backdrop, Slider, Snackbar, Tooltip, Skeleton } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Forward10Icon from "@mui/icons-material/Forward10";
import Replay10Icon from "@mui/icons-material/Replay10";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import SpeedIcon from "@mui/icons-material/Speed";
import PlayerDrawer from "./Drawer/Drawer";
import { Favorite, FavoriteBorderOutlined, Mic } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getAuthorsString, getRandomAudio } from "../../api/audios";
import { addRecentActivity } from "../../api/recent";
import {
  displayControlsOnLockScreen,
  isIOS,
  share,
} from "../../utils/progressive";
import { convertHhMmSsToSeconds, secondsToMinutes } from "../../utils/strings";
import Share from "../Share/Share";
import { getReadPresignedUrl, getAudio } from "../../api/audios";
import {
  addAudioToFavorites,
  handleLocalStorageData,
  removeAudioFromFavorites,
  verifyIfIsFavorite,
} from "../../api/favorites";
import { notify } from "../../App";
import AddToPlaylist from "./AddToPlaylist/AddToPlaylist";
import Speed from "./Speed/Speed";
import Lyrics from "./Lyrics/Lyrics";
import { sendPlayRequest } from "../../utils/mobile";
import { notifyBugsnag } from "../../utils/BugsnagService";

export default function Player(props) {
  const {
    showPlayer,
    setShowPlayer,
    audio,
    setAudio,
    isPlaying,
    setIsPlaying,
    queue,
    setQueue,
    playingACollection,
    setPlayingACollection,
    collection,
    setCollection,
    collectionId,
    setCollectionId,
  } = props;

  const [progress, setProgress] = useState(0);
  const [progressValue, setProgressValue] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [fullScreen, setFullScreen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isTracktimeUpdating, setIsTracktimeUpdating] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAddToPlaylist, setOpenAddToPlaylist] = useState(false);
  const [openSpeed, setOpenSpeed] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [audioUrl, setAudioUrl] = useState("");
  const [speed, setSpeed] = useState(1.0);
  const [volume, setVolume] = useState(100);
  const [volumeSliderVisible, setVolumeSliderVisible] = useState(false);
  const [next, setNext] = useState({});
  const [openLyrics, setOpenLyrics] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastAudio, setLastAudio] = useState({});
  const [loading, setLoading] = useState(true);

  const resize = () => {
    setScreenWidth(window.innerWidth);
  };

  /* eslint-disable */
  const decideFullScreen = () => {
    if (screenWidth < 570 && !isUpdating) {
      setFullScreen(true);
    }

    setIsUpdating(true);
  };

  const checkFavorite = () => {
    if (audio) {
      const isFavorite = verifyIfIsFavorite(audio.id, "audios");
      setIsFavorite(isFavorite);
    }
  };

  useEffect(() => {
    setPlaylists(handleLocalStorageData("playlists"));
    catchSharedAudioFromUrl();
    console.log(setSearchParams);
    console.log(next);
  }, []);

  useEffect(() => {
    decideFullScreen();
    window.addEventListener("resize", resize);
    checkFavorite();
    if (audio) {
      displayControlsOnLockScreen(audio);
    }
    cleanQueueFromRepeatedIds();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [screenWidth, audio]);
  /* eslint-enable */

  const handleStart = async () => {
    if (!audio) return;
    addRecentActivity(audio);
    if (localStorage.getItem("nativeAudio")) {
      sendPlayRequest(collectionId, collection, audio);
      setCollection(null);
      return;
    }
    // TODO: THIS HAS TO BE MODIFIED TO ONLY PLAY THROUGH PRESIGNED URL
    if (!audio.url.includes("https://")) {
      const res = await getReadPresignedUrl(audio.url);
      setAudioUrl(res.url);
    }

    setProgress(0);
    setProgressValue(0);
    addToQueue(audio);
    setSpeed(1.0);

    if (!isIOS()) {
      const audioPlayer = document.getElementById("audioPlayer");

      const tracktimePosition = parseFloat(
        localStorage.getItem("tracktimePosition")
      );
      const lastAudio = getLastAudioPlayed();
      if (tracktimePosition) {
        setProgress(tracktimePosition);
        setProgressValue(
          (tracktimePosition * 100) / convertHhMmSsToSeconds(lastAudio.duration)
        );
        audioPlayer.currentTime = tracktimePosition;
      }
    }

    if (queue[queue.length - 1].id === audio.id && collectionId === "") {
      getRandomAudio().then((res) => {
        if (res.data.id !== audio.id) {
          setNext(res.data);
          addToQueue(res.data);
          setLoading(false);
          if (!playingACollection) {
            setPlayingACollection(true);
            setCollection(queue);
            setLoading(false);
          }
        }
      });
    }
  };

  const cleanQueueFromRepeatedIds = () => {
    let newQueue = [];
    queue.forEach((item) => {
      if (item.id !== audio.id) {
        newQueue.push(item);
      }
    });
    setQueue(newQueue);
  };

  useEffect(() => {
    setAudioUrl(audio?.url);
  }, [audio]);

  const handlePlay = () => {
    if (isPlaying) {
      document.getElementById("audioPlayer").pause();
      setIsPlaying(false);
    }

    if (!isPlaying) {
      document.getElementById("audioPlayer").play();
      setIsPlaying(true);
    }
  };

  const handleUpdate = () => {
    if (audio) {
      const audioPlayer = document.getElementById("audioPlayer");
      setProgress(audioPlayer.currentTime.toFixed(0));
      if (!isTracktimeUpdating) {
        setProgressValue(
          (audioPlayer.currentTime / convertHhMmSsToSeconds(audio.duration)) *
          100
        );
      }
    }
  };

  const handleProgress = (event, newValue) => {
    setIsTracktimeUpdating(true);
    setProgressValue(newValue);
  };

  const commitProgress = () => {
    setIsTracktimeUpdating(false);
    let audio = document.getElementById("audioPlayer");
    audio.currentTime = (progressValue / 100) * audio.duration;
  };

  const handlePrevious = async () => {
    setIsUpdating(true);
    localStorage.setItem("tracktimePosition", 0);
    const audioPlayer = document.getElementById("audioPlayer");
    if (queue.length > 1) {
      if (progress > 10) {
        audioPlayer.currentTime = 0;
        setProgress(0);
      } else {
        if (playingACollection && collectionId) {
          const index = collection.findIndex((a) => a.id === audio.id);
          if (index > 0) {
            const previousAudio = collection[index - 1];
            setAudio(previousAudio);
          } else {
            audioPlayer.currentTime = 0;
            setProgress(0);
          }
        } else {
          // play previous of queue
          const index = queue.findIndex((a) => a.id === audio.id);
          if (index > 0) {
            const previousAudio = queue[index - 1];
            setAudio(previousAudio);
          }
        }
      }
    } else {
      audioPlayer.currentTime = 0;
      setProgress(0);
    }
  };

  const handleNext = async () => {
    setIsUpdating(true);
    setIsPlaying(false);
    setProgress(0);
    setProgressValue(0);
    setOpenLyrics(false);
    localStorage.setItem("tracktimePosition", 0);
    if (playingACollection && collectionId !== "") {
      const actualAudioIndex = collection.findIndex((a) => a.id === audio.id);
      if (actualAudioIndex < collection.length - 1) {
        const nextAudio = collection[actualAudioIndex + 1];
        setAudio(nextAudio);
      } else {
        const res = await getRandomAudio();
        setAudio(res.data);
        setPlayingACollection(false);
        setCollection({});
        setCollectionId("");
      }
    } else {
      const res = await getRandomAudio();
      setAudio(res.data);
      setPlayingACollection(false);
      setCollection({});
      setCollectionId("");
    }
    setIsPlaying(true);
  };

  const handleClickFullScreen = () => {
    if (screenWidth < 500) {
      setFullScreen(true);
    } else {
      if (playingACollection) {
        // if collection includes current audio, go to collection page
        if (collection.find((a) => a.id === audio.id)) {
          navigate(`/collection/${collectionId}`);
        } else {
          setPlayingACollection(false);
          setCollection({});
          setCollectionId("");
        }
      }
    }
  };

  const handleAddToPlaylist = () => {
    setOpenAddToPlaylist(true);
  };

  const handleFavorite = () => {
    setIsFavorite(!isFavorite);
    if (!isFavorite) {
      addAudioToFavorites(audio);
      notify("Añadido a tus favoritos");
    } else {
      removeAudioFromFavorites(audio);
      notify("Eliminado de tus favoritos");
    }
  };

  const reloadPlaylists = () => {
    setPlaylists(handleLocalStorageData("playlists"));
  };

  const handleSpeed = (value) => {
    setSpeed(value);
    document.getElementById("audioPlayer").playbackRate = value;
  };

  const addToQueue = (id) => {
    if (!queue.includes(id)) {
      queue.push(id);
      setQueue(queue);
    }
  };

  const putVolume = (value) => {
    setVolume(value);
    document.getElementById("audioPlayer").volume = value / 100;
  };

  const catchSharedAudioFromUrl = () => {
    const audioId = searchParams.get("t");
    if (audioId) {
      getAudio(audioId)
        .then((res) => {
          setAudio(res.data);
          setShowPlayer(true);
          setIsPlaying(true);
          const audioPlayer = document.getElementById("audioPlayer");
          audioPlayer.play();
          searchParams.delete("t");
          navigate("/");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          notifyBugsnag(err);
          navigate("/");
        });
    }
  };

  const handleTenSecondsAction = (direction) => {
    const audioPlayer = document.getElementById("audioPlayer");
    if (direction === "back") {
      audioPlayer.currentTime -= 10;
    } else {
      audioPlayer.currentTime += 10;
    }
  };

  const getPretendedMinute = () => {
    const audioPlayer = document.getElementById("audioPlayer");
    if (isTracktimeUpdating && audio) {
      return secondsToMinutes((audioPlayer.duration * progressValue) / 100);
    } else {
      return secondsToMinutes(progress);
    }
  };

  /* eslint-disable */
  const getLastAudioPlayed = () => {
    const recentAudios = localStorage.getItem("recent");
    if (recentAudios) {
      const recentAudiosArray = JSON.parse(recentAudios);
      if (recentAudiosArray.length > 0) {
        const lastAudio = recentAudiosArray[recentAudiosArray.length - 1];
        return lastAudio;
      }
    }
  };

  const saveAudioProgress = () => {
    if (!audio) return;

    const audioPlayer = document.getElementById("audioPlayer");
    if (audioPlayer) {
      localStorage.setItem("tracktimePosition", audioPlayer.currentTime);
    }
  };

  useEffect(() => {
    const lastAudio = getLastAudioPlayed();
    if (lastAudio) {
      setAudio(lastAudio);
      setIsPlaying(false);
      setFullScreen(false);
      setShowPlayer(true);
      setProgress(0);
      setProgressValue(0);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      saveAudioProgress();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleBeforeUnload);
    window.addEventListener("pagehide", handleBeforeUnload);
    window.addEventListener("onblur", handleBeforeUnload);
    window.addEventListener("visibilitychange", handleBeforeUnload);
    window.addEventListener("onclose", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleBeforeUnload);
      window.removeEventListener("pagehide", handleBeforeUnload);
      window.removeEventListener("onblur", handleBeforeUnload);
      window.removeEventListener("visibilitychange", handleBeforeUnload);
      window.removeEventListener("onclose", handleBeforeUnload);
    };
  }, [audio]);

  if (localStorage.getItem("nativeAudio")) {
    if (lastAudio === audio) return;
    if (!isPlaying) return;
    handleStart();
    setLastAudio(audio);
    return;
  }

  return (
    <>
      {audio ? (
        <Snackbar
          className={fullScreen ? "fullscreenPlayer" : "bottomBarPlayer"}
          open={showPlayer}
          sx={{
            height: fullScreen ? "100vh" : "auto",
            backgroundColor:
              audio.color != null && fullScreen
                ? audio.color
                : "primary.softDark",
            transition: "background-color 0.5s ease",
          }}
        >
          {
            loading === false ?

              <div
                className={
                  fullScreen ? "fullScreenPlayerData" : "bottomBarPlayerData"
                }
                onMouseLeave={() => setVolumeSliderVisible(false)}
              >
                {fullScreen ? (
                  <div className="fullScreenPlayerHeader">
                    <KeyboardArrowDownIcon
                      onClick={() => setFullScreen(false)}
                      sx={{ fontSize: 30 }}
                    />
                    <Share
                      item={audio}
                      type="audio"
                      open={openDrawer}
                      setOpen={setOpenDrawer}
                    />
                  </div>
                ) : null}
                <div
                  className={
                    fullScreen ? "fullScreenPlayerLeft" : "bottomBarPlayerDataLeft"
                  }
                  onClick={() => handleClickFullScreen()}
                >
                  <img src={audio.thumbnail} alt="cover" />
                  {fullScreen && audio.lyrics ? (
                    <div
                      className="fullScreenPlayerLyrics"
                      onClick={() => setOpenLyrics(true)}
                    >
                      <Mic sx={{ fontSize: 20 }} />
                      <p>Letra</p>
                    </div>
                  ) : null}
                  <div
                    className={
                      fullScreen
                        ? "fullScreenPlayerInfo"
                        : "bottomBarPlayerDataText"
                    }
                  >
                    <h3
                      className={
                        fullScreen
                          ? "fullScreenPlayerTitle"
                          : "bottomBarPlayerDataTextTitle"
                      }
                    >
                      {audio.title}
                    </h3>
                    <p className="bottomBarPlayerDataTextAuthor">
                      {getAuthorsString(audio.authors)}
                    </p>
                  </div>
                </div>
                <div
                  className={
                    fullScreen
                      ? "fullScreenPlayerCenter"
                      : "bottomBarPlayerDataCenter"
                  }
                >
                  <div className="bottomBarPlayerDataRightButtons">
                    {fullScreen || screenWidth > 1200 ? (
                      <Tooltip title="Compartir">
                        <ShareIcon
                          sx={{
                            fontSize: fullScreen ? 30 : 20,
                            marginRight: fullScreen ? "30px" : "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => share(audio, "audio")}
                        />
                      </Tooltip>
                    ) : null}
                    <Tooltip title="Atrás">
                      <SkipPreviousIcon
                        className="bottomBarPlayerDataRightButtonsIcon FastRewindIcon"
                        sx={{
                          fontSize: fullScreen ? 40 : 30,
                          cursor: "pointer",
                        }}
                        onClick={() => handlePrevious()}
                      />
                    </Tooltip>
                    {isPlaying ? (
                      <Tooltip title="Pausar">
                        <PauseIcon
                          className="bottomBarPlayerDataRightButtonsIcon"
                          onClick={() => handlePlay()}
                          sx={{ fontSize: fullScreen ? 50 : 30, cursor: "pointer" }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Reproducir">
                        <PlayArrowIcon
                          className="bottomBarPlayerDataRightButtonsIcon"
                          onClick={() => handlePlay()}
                          sx={{ fontSize: fullScreen ? 50 : 30, cursor: "pointer" }}
                        />
                      </Tooltip>
                    )}
                    <Tooltip title="Siguiente">
                      <SkipNextIcon
                        className="bottomBarPlayerDataRightButtonsIcon"
                        sx={{ fontSize: fullScreen ? 40 : 30, cursor: "pointer" }}
                        onClick={() => handleNext()}
                      />
                    </Tooltip>

                    {fullScreen || screenWidth > 1200 ? (
                      isFavorite ? (
                        <Tooltip title="Quitar de favoritos">
                          <Favorite
                            sx={{
                              fontSize: fullScreen ? 30 : 20,
                              marginLeft: fullScreen ? "30px" : "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleFavorite()}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Añadir a favoritos">
                          <FavoriteBorderOutlined
                            sx={{
                              fontSize: fullScreen ? 30 : 20,
                              marginLeft: fullScreen ? "30px" : "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleFavorite()}
                          />
                        </Tooltip>
                      )
                    ) : null}

                    {screenWidth > 1200 ? (
                      <Tooltip title="Añadir a playlist">
                        <PlaylistAddIcon
                          sx={{
                            fontSize: fullScreen ? 40 : 30,
                            marginLeft: fullScreen ? "30px" : "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleAddToPlaylist()}
                        />
                      </Tooltip>
                    ) : null}
                    {screenWidth > 1200 && audio.type != 1 ? (
                      <Tooltip title="Velocidad">
                        <SpeedIcon
                          sx={{
                            fontSize: fullScreen ? 40 : 30,
                            marginLeft: fullScreen ? "30px" : "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => setOpenSpeed(true)}
                        ></SpeedIcon>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>

                <div
                  className={
                    fullScreen
                      ? "fullScreenPlayerRight"
                      : "bottomBarPlayerDataRight"
                  }
                >
                  <div className="bottomBarPlayerDataRightProgress">
                    {screenWidth > 1200 ? (
                      <div
                        className="bottomBarPlayerDataRightVolume"
                        onMouseEnter={() => setVolumeSliderVisible(true)}
                      >
                        {screenWidth > 500 ? (
                          <Slider
                            size="small"
                            value={volume}
                            onChange={(e, value) => putVolume(value)}
                            aria-labelledby="continuous-slider"
                            sx={{
                              width: "60px",
                              color: "white",
                              visibility: volumeSliderVisible
                                ? "visible"
                                : "hidden",
                            }}
                          />
                        ) : null}
                        <div className="bottomBarPlayerDataRightVolumeIcon">
                          {volume > 0 ? (
                            <Tooltip title="Volumen">
                              <VolumeUpIcon
                                sx={{
                                  fontSize: 20,
                                  color: "white",
                                  marginLeft: "15px",
                                  marginRight: "15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => putVolume(0)}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Volumen">
                              <VolumeOffIcon
                                sx={{
                                  fontSize: 20,
                                  color: "white",
                                  marginLeft: "15px",
                                  marginRight: "15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => putVolume(100)}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    ) : null}
                    <p className="progress">{secondsToMinutes(progress)}</p>
                    <Slider
                      size="small"
                      value={progressValue}
                      onChange={handleProgress}
                      onChangeCommitted={commitProgress}
                      sx={{
                        width: fullScreen ? 200 : 150,
                        color: "#fff",
                      }}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => getPretendedMinute()}
                    />
                    <p className="duration">{audio.duration}</p>
                  </div>
                </div>
                {screenWidth < 500 && fullScreen ? (
                  <div className="LowerControls">
                    <Replay10Icon
                      sx={{ fontSize: 35, cursor: "pointer" }}
                      onClick={() => handleTenSecondsAction("back")}
                    />
                    <Forward10Icon
                      sx={{ fontSize: 35, cursor: "pointer" }}
                      onClick={() => handleTenSecondsAction("forward")}
                    />
                  </div>
                ) : null}
                {fullScreen ? (
                  <Backdrop
                    open={openDrawer}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    onClick={() => setOpenDrawer(false)}
                  >
                    <PlayerDrawer
                      open={openDrawer}
                      setOpen={setOpenDrawer}
                      item={audio}
                      isFavorite={isFavorite}
                      handleFavorite={handleFavorite}
                      openAddToPlaylist={openAddToPlaylist}
                      setOpenAddToPlaylist={setOpenAddToPlaylist}
                      reloadPlaylists={reloadPlaylists}
                      openSpeed={openSpeed}
                      setOpenSpeed={setOpenSpeed}
                    />
                  </Backdrop>
                ) : null}
                <AddToPlaylist
                  open={openAddToPlaylist}
                  setOpen={setOpenAddToPlaylist}
                  item={audio}
                  playlists={playlists}
                  setFullScreen={setFullScreen}
                />
                <Speed
                  open={openSpeed}
                  setOpen={setOpenSpeed}
                  speed={speed}
                  handleSpeed={handleSpeed}
                />
                <Lyrics open={openLyrics} setOpen={setOpenLyrics} audio={audio} />
              </div>
              :
              <div style={{ position: 'relative', width: '100%', height: '8.5vh' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: '30px',
                  }}
                ></div>

                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: '10px',
                    right: '10px',
                    height: '115px',
                  }}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
          }
        </Snackbar>
      ) : null}
      <audio
        className="audioPlayer"
        src={audioUrl}
        autoPlay={isPlaying}
        id="audioPlayer"
        onLoadStart={() => handleStart()}
        onEnded={() => handleNext()}
        onTimeUpdate={() => handleUpdate()}
        hidden
      />
    </>
  );
}
