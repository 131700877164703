import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { messages } from "../../../utils/ux-writing";

export default function PlaylistCreated(props) {
  const { open, setOpen } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const resize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "#161833",
          width: window.innerWidth > 570 ? "500px" : "90%",
          height: "300px",
          margin: "auto",
          marginTop: screenWidth > 570 ? "10%" : "40%",
          padding: "1px 10px 0px 10px",
        }}
      >
        <div className="uploadResult">
          <h3>{messages.successPlaylist}</h3>
          <div className="icon">
            <CheckCircleIcon sx={{ fontSize: 100, color: "#00FF00" }} />
          </div>
          <p className="resultMsg">{messages.successPlaylistMessage}</p>
        </div>
      </Box>
    </Modal>
  );
}
