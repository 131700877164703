import React, { useEffect, useState } from "react";
import "./Setup.css";
import { Tab, Tabs } from "@mui/material";
import { Button } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";

import img1 from "../../assets/images/install/1.jpg";
import img2 from "../../assets/images/install/2.jpg";
import img3 from "../../assets/images/install/3.jpg";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function Setup() {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const platform = window.navigator.platform;
    if (platform === "iPhone") {
      setValue(1);
    }
  }, []);

  return (
    <div className="Setup View">
      <div className="Setup-header">
        <Link
          to="/login"
          style={{ color: "#fff", display: "flex", alignItems: "center" }}
        >
          <ArrowBack />
        </Link>
        <h3>Guía de instalación y uso</h3>
      </div>
      <div className="Setup-body">
        <Tabs
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            width: "90vw",
            "& .MuiTabs-indicator": {
              backgroundColor: "#fff",
            },
            "& .Mui-selected": {
              color: "primary.contrast",
            },
            "& .MuiTab-root": {
              color: "primary.main",
            },
          }}
        >
          <Tab label="Android"></Tab>
          <Tab label="iOS"></Tab>
        </Tabs>
        <div className="Setup-body-content">
          {value === 0 ? (
            <div className="Setup-body-content-android">
              <h4>Instalar Rhema en Android</h4>
              <p>
                Debes hacer click en el menú de tres puntos que se encuentra en
                la parte superior derecha del navegador.
              </p>
              <img src={img1} alt="img1" />
              <br />
              <p>Luego, selecciona la opción "Instalar aplicación".</p>
              <img src={img2} alt="img2" />
              <br />
              (En algunos dispositivos puede como "Agregar a pantalla
              principal")
              <br />
              <p>
                Esto abrirá una ventana emergente en la que se te pedirá
                confirmar la instalación.
              </p>
              <img src={img3} alt="img3" />
              <br />
              <p>Y listo! ya podrás usar Rhema en tu dispositivo Android.</p>
            </div>
          ) : (
            <div className="Setup-body-content-ios">
              <h4>Instalar Rhema en iOS</h4>
              <p>
                Para instalar Rhema en iOS, debes hacerlo desde la App Store.
              </p>
              <a
                href="https://apps.apple.com/us/app/rhema/id1660475760"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "primary.contrast",
                    color: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                  startIcon={<AppleIcon />}
                >
                  Ir a la App Store
                </Button>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
