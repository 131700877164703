import React, { useEffect } from "react";
import "../Collection/Collection.css";
import "./Favorites.css";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import CollectionItem from "../../components/CollectionItem/CollectionItem";
import usePlayer from "../../hooks/usePlayer";
import { handleLocalStorageData } from "../../api/favorites";
import getActiveItemClass from "../../utils/getItemClass";

export default function Favorites() {
  const audios = handleLocalStorageData("favoritesAudios");
  audios.reverse();
  const {
    audio: currentAudio,
    setShowPlayer,
    setAudio,
    setIsPlaying,
    setQueue,
    setPlayingACollection,
    setCollection,
    setCollectionId,
  } = usePlayer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePlay = (audio) => {
    localStorage.setItem("tracktimePosition", 0);
    setCollection(audios);
    setPlayingACollection(true);
    setCollectionId("favorites");
    setAudio(audio);
    setIsPlaying(true);
    setShowPlayer(true);
    setQueue([audio]);
  };

  return (
    <div className="View Collection">
      <div className="ViewHeader CollHeader">
        <Link to="/library" className="Link ArrowBack">
          <ArrowBackIcon sx={{ fontSize: 30 }} />
        </Link>
        <div>
          <h2 className="ViewTitle CollTitle">Tus favoritos</h2>
          <p className="ViewDescription">
            {audios.length === 1 ? "1 elemento" : `${audios.length} elementos`}
          </p>
        </div>
      </div>
      <div className="FavoritesCollection">
        {audios.map((audio, index) => (
          <div
            className={getActiveItemClass(
              audio.id,
              currentAudio,
              "FavoritesItem"
            )}
            key={audio.id}
            onClick={() => handlePlay(audio)}
          >
            <CollectionItem item={audio} key={index} />
          </div>
        ))}
      </div>
    </div>
  );
}
