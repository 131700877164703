import axios from "axios";

import { config } from "../config";
import { getAccessToken } from "./token";

export const getTags = async () => {
  const lastRefresh = localStorage.getItem("lastTagsRefresh");
  const tags = JSON.parse(localStorage.getItem("tags"));

  const now = new Date().getTime();
  const halfHour = 1000 * 60 * 30;
  if ((lastRefresh && now - lastRefresh > halfHour) || !lastRefresh || !tags) {
    return axios
      .get(config.coreApiUrl + "/tags", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAccessToken(),
        },
      })
      .then((res) => {
        localStorage.setItem("tags", JSON.stringify(res.data));
        localStorage.setItem("lastTagsRefresh", now);
        return res.data;
      });
  } else {
    return new Promise((resolve) => {
      resolve(tags);
    });
  }
};
