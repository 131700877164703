import axios from "axios";

import { config } from "../config";
import { getAccessToken } from "./token";

export const getPastors = async () => {
  const response = await axios.get(`${config.coreApiUrl}/authors/pastors`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });

  return response.data;
};
