import React, { useState } from "react";
import { Box, Button, Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { deletePlaylist } from "../../../../api/playlists";
import { notify } from "../../../../App";
import { notifyBugsnag } from "../../../../utils/BugsnagService";

export default function ConfirmDeletion(props) {
  const { open, setOpen, item, setOpenEditPlaylist } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const confirmDeletion = () => {
    setLoading(true);
    deletePlaylist(item.id)
      .then((res) => {
        setOpenEditPlaylist(false);
        setOpen(false);
        navigate("/library");
        notify("Playlist eliminada");
      })
      .catch((err) => {
        console.log(err);
        notifyBugsnag(err);
        notify("Ha ocurrido un error");
      });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} variant="persistent">
      <Box
        sx={{
          width: "270px",
          height: "20vh",
          padding: "0px 10px",
          backgroundColor: "primary.softDark",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <h3>Borrar playlist</h3>
        <Box
          sx={{ display: "flex", justifyContent: "right", paddingBottom: 1 }}
        >
          <Button
            sx={{
              backgroundColor: "primary.softDark",
              color: "white",
            }}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
          <Button
            sx={{
              marginLeft: "10px",
              backgroundColor: "primary.main",
              color: "primary.dark",
            }}
            onClick={() => {
              confirmDeletion();
            }}
            disabled={loading}
          >
            Borrar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
