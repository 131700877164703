import React, { useState, useEffect, useMemo } from "react";
import "../Collection/Collection.css";
import "./FilterByTag.css";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CollectionItem from "../../components/CollectionItem/CollectionItem";
import { getAudiosByTag } from "../../api/audios";
import { Capitalize } from "../../utils/strings";
import usePlayer from "../../hooks/usePlayer";
import useSearch from "../../hooks/useSearch";
import getActiveItemClass from "../../utils/getItemClass";

export default function FilterByTag() {
  const tag = window.location.pathname.split("/")[2];
  const decoded = decodeURI(tag);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([1, 2, 3, 4, 5]);
  const {
    audio: currentAudio,
    setShowPlayer,
    setAudio,
    setIsPlaying,
    setQueue,
    setPlayingACollection,
    setCollection,
  } = usePlayer();
  const { showSearch, setShowSearch } = useSearch();
  const navigate = useNavigate();

  const specialTags = useMemo(() => {
    return [
      {
        name: "RETIRO",
        order: "asc",
      },
      {
        name: "PREDICACIONES",
        order: "desc",
      },
    ];
  }, []);

  const sortByDate = (items, order = "asc") => {
    return items.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      const diff = dateA - dateB;
      if (order === "asc") {
        return diff;
      } else {
        return -diff;
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAudiosByTag(tag).then((res) => {
      const specialTag = specialTags.find((t) => tag.includes(t.name));
      if (specialTag) {
        setItems(sortByDate(res.data, specialTag.order));
      } else {
        setItems(res.data);
      }
      setLoading(false);
    });
  }, [tag, specialTags]);

  const handlePlay = (audio) => {
    localStorage.setItem("tracktimePosition", 0);
    console.log(items);
    setCollection(items);
    setPlayingACollection(true);
    setAudio(audio);
    setIsPlaying(true);
    setShowPlayer(true);
    setQueue([audio]);
  };

  const handleBack = () => {
    if (showSearch) {
      setShowSearch(false);
    }

    if (window.history.length > 1) {
      navigate(-1);
    }

    navigate("/");
  };

  return (
    <div className="View Collection">
      <div className="ViewHeader CollHeader">
        <Link to="/" className="Link ArrowBack" onClick={handleBack}>
          <ArrowBackIcon sx={{ fontSize: 30 }} />
        </Link>
        <div>
          <h2 className="ViewTitle CollTitle">
            {loading ? <Skeleton width={250} /> : Capitalize(decoded)}
          </h2>
        </div>
      </div>
      <div className="TagsCollection">
        {loading
          ? items.map((item, index) => (
              <div className="TagItem" key={index}>
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={100}
                  className="CollectionItemImg"
                  sx={{ borderRadius: "5px" }}
                />
                <div className="CollectionItemInfo">
                  <Skeleton width={200} />
                  <Skeleton width={100} />
                </div>
              </div>
            ))
          : items.map((item, index) => (
              <div
                className={getActiveItemClass(item.id, currentAudio, "TagItem")}
                key={item.id}
                onClick={() => handlePlay(item)}
              >
                <CollectionItem item={item} key={index} />
              </div>
            ))}
      </div>
    </div>
  );
}
