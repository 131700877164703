import React, { useState } from "react";
import "../../../components/Player/Drawer/Drawer.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Edit, DeleteOutline, Share } from "@mui/icons-material";
import List from "@mui/material/List";
import ConfirmDeletion from "./ConfirmDeletion/ConfirmDeletion";
import { share } from "../../../utils/progressive";
import { paddingBottom } from "../../../utils/mobile";
export default function PlaylistDrawer(props) {
  const { open, setOpen, item, setOpenEditPlaylist } = props;
  const [openConfirmDeletion, setOpenConfirmDeletion] = useState(false);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      variant="persistent"
    >
      <Box
        sx={{
          width: "100%",
          height: "max-content",
          backgroundColor: "primary.dark",
          paddingBottom: paddingBottom,
        }}
        role="presentation"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
      >
        <List
          sx={{
            padding: "10px",
            marginBottom: "20px",
            paddingTop: "20px",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <div className="DrawerItem" onClick={() => share(item, "playlist")}>
            <Share />
            <span>Compartir playlist</span>
          </div>
          <div className="DrawerItem" onClick={() => setOpenEditPlaylist(true)}>
            <Edit sx={{ fontSize: "25px" }} />
            <span>Editar playlist</span>
          </div>
          <div
            className="DrawerItem"
            onClick={() => setOpenConfirmDeletion(true)}
          >
            <DeleteOutline sx={{ fontSize: "25px" }} />
            <span>Eliminar playlist</span>
          </div>
        </List>
      </Box>
      <ConfirmDeletion
        open={openConfirmDeletion}
        setOpen={setOpenConfirmDeletion}
        item={item}
        setOpenEditPlaylist={setOpenEditPlaylist}
      />
    </Drawer>
  );
}
