import React, { useState, useEffect } from "react";
import "./Home.css";

import Header from "../../components/Header/Header";
import Tags from "../../components/Tags/Tags";
import ItemsGallery from "../../components/ItemsGallery/ItemsGallery";
import { getRecentActivity } from "../../api/recent";

import { getHome } from "../../api/audios";
import useSearch from "../../hooks/useSearch";

function Home() {
  const [homeItems, setHomeItems] = useState([{}, {}, {}, {}, {}]);
  const [loading, setLoading] = useState(true);
  const { showSearch, setShowSearch } = useSearch();

  useEffect(() => {
    getHome().then((res) => {
      const newContentGallery = res.findIndex((item) => {
        return item.title === "Nuevo";
      });
      res.splice(0, 0, res.splice(newContentGallery, 1)[0]);

      let recent = getRecentActivity();
      if (recent.length >= 5) {
        recent = recent.slice(recent.length - 5, recent.length);
        recent.reverse();

        const recentGallery = {
          title: "Escuchar otra vez",
          items: recent,
        };
        res.splice(1, 0, recentGallery);
      }
      setHomeItems(res);
      setLoading(false);
    });
  }, []);

  /* eslint-disable */
  useEffect(() => {
    if (showSearch) {
      setShowSearch(false);
    }
  }, []);
  /* eslint-enable */

  return (
    <div className="View Home">
      <Header />
      <Tags />
      {loading ? (
        <>
          <ItemsGallery
            title="Nuevo"
            type="singles"
            loading={true}
            items={[{}, {}, {}, {}, {}]}
          />
          <ItemsGallery
            title="Popular"
            type="singles"
            loading={true}
            items={[{}, {}, {}, {}, {}]}
          />
          <ItemsGallery
            title="Recomendación diaria"
            type="daily"
            loading={true}
            items={[{}, {}, {}, {}, {}]}
          />
          <ItemsGallery
            title="Series"
            type="collections"
            loading={true}
            items={[{}, {}, {}, {}, {}]}
          />
          <ItemsGallery
            title="Álbumes"
            type="collections"
            loading={true}
            items={[{}, {}, {}, {}, {}]}
          />
          <ItemsGallery
            title="Podcasts"
            type="collections"
            loading={true}
            items={[{}, {}, {}, {}, {}]}
          />
        </>
      ) : (
        <>
          {homeItems.map((item) => {
            return (
              <ItemsGallery
                title={item.title}
                loading={false}
                items={item.items}
                key={item.title}
              />
            );
          })}
        </>
      )}
    </div>
  );
}

export default Home;
