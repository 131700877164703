export const saveUserData = async (data) => {
  localStorage.setItem("user", JSON.stringify(data));
};

export const getUserData = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

export const removeUserData = () => {
  localStorage.removeItem("user");
};

export const saveAvatar = (url) => {
  localStorage.setItem("avatar", JSON.stringify(url));
};

export const readAvatar = () => {
  const avatar = localStorage.getItem("avatar");
  return avatar ? JSON.parse(avatar) : null;
}