import React from "react";
import ReactDOM from "react-dom/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { registrationConfig } from "./utils/progressive";

const root = ReactDOM.createRoot(document.getElementById("root"));

const userObject = JSON.parse(localStorage.getItem("user"));

const username = userObject?.username;
const email = userObject?.email;

if (username && email) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    user: {
      email: email,
      name: username,
    },
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_KEY });
}

const ErrorBoundaryWrapper = username && email ? Bugsnag.getPlugin("react").createErrorBoundary(React) : React.Fragment;

root.render(
  <ErrorBoundaryWrapper>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundaryWrapper>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register(registrationConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
