import React, { useState, useEffect } from "react";
import { Modal, Box, Button, CircularProgress, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./ProblemModal.css";
import { useFormik } from "formik";

import { hideNavBarAndPlayer, showNavBarAndPlayer } from "../../../utils/ux";
import { createIssue } from "../../../api/issues";
import UploadResult from "../../Modals/UploadResult/UploadResult";

export default function ProblemModal(props) {
  const { open, setOpen, item } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const resize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      title: "",
      detail: "",
    },

    validate: (values) => {
      const errors = {};
      if (values.title === "" || values.title.trim() === "") {
        errors.title = "El título es requerido";
      }
      if (values.detail === "" || values.detail.trim() === "") {
        errors.detail = "El detalle es requerido";
      }
      return errors;
    },

    onSubmit: async (values) => {
      setLoading(true);
      await createIssue(values.title, values.detail);
      setOpenSuccess(true);
      setLoading(false);
      setOpen(false);
      formik.resetForm();
    },
  });

  return (
    <div className="modal-problem">
      <Modal
        openSuccess={false}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#161833",
            borderRadius: "10px",
            width: window.innerWidth > 570 ? "500px" : "80%",
            height: "fit-content",
            margin: "auto",
            marginTop: screenWidth > 570 ? "10%" : "40%",
            padding: "1px 25px 0px 25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="top-content">
              <h2>Reportar un problema</h2>
              <CloseIcon
                sx={{
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                onClick={() => setOpen(false)}
              />
            </div>
            <TextField
              className="outlined-basic"
              inputProps={{ maxLength: 30 }}
              sx={{ width: "100%" }}
              placeholder="Resumen/Título"
              name="title"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.title}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              onFocus={() => hideNavBarAndPlayer()}
              onBlur={() => showNavBarAndPlayer()}
            />
            <TextField
              className="detail-textarea"
              sx={{
                width: "100%",
                marginTop: "10px",
                overflowY: "auto",
              }}
              multiline
              variant="outlined"
              rows={6}
              placeholder="Detalle"
              name="detail"
              onChange={formik.handleChange}
              value={formik.values.detail}
              error={formik.touched.detail && Boolean(formik.errors.detail)}
              helperText={formik.touched.detail && formik.errors.detail}
              onFocus={() => hideNavBarAndPlayer()}
              onBlur={() => showNavBarAndPlayer()}
            />
            <div className="modal-button">
              <Button
                type="submit"
                onMouseDown={() => formik.handleSubmit()}
                disabled={loading || formik.values.title === item?.title}
                variant="contained"
                sx={{
                  marginTop: "20px",
                  color: "#fff",
                  backgroundColor: "primary.contrast",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                {loading ? (
                  <CircularProgress size={20} sx={{ color: "primary.main" }} />
                ) : (
                  "Enviar"
                )}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <UploadResult open={openSuccess} setOpen={setOpenSuccess} ok={true} />
    </div>
  );
}
