import React, { useState, useEffect } from "react";
import "./Header.css";
import logo from "../../assets/logo.svg";
import jwtDecode from "jwt-decode";

import NavBar from "../NavBar/NavBar";
import SearchText from "../SearchText/SearchText";
import useAuth from "../../hooks/useAuth";
import UserOptions from "../UserOptions/UserOptions";

export default function Header() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { auth, logout } = useAuth();
  const username = auth ? jwtDecode(auth.token).username : null;

  const resize = () => {
    setScreenWidth(window.innerWidth);
  };

  /* eslint-disable */
  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  /* eslint-enable */

  return (
    <div className="Header">
      <div className="Brand">
        <img src={logo} alt="logo" className="img-icon logo" />
      </div>
      <div>
        <SearchText />
        {screenWidth > 570 ? <NavBar className="HeaderNavBar"></NavBar> : null}
      </div>
      <div className="HeaderRight">
        <UserOptions username={username} logout={logout} />
      </div>
    </div>
  );
}
