import React, { useState } from "react";
import "./NavBar.css";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import Search from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

export default function BottomNav() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const routes = ["/", "", "/library"];

  const handleSearch = () => {
    navigate("/search");
  };

  const menuChange = (event, newValue) => {
    if (newValue !== 1) {
      setValue(newValue);
      navigate(routes[newValue]);
    } else {
      handleSearch();
    }
  };

  const onMenuChange = (message) => {
    const data = JSON.parse(message.data);
    if (data.action !== "handle-menu") return;
    menuChange(null, data.option);
  };

  if (navigator.platform === "iPhone")
    window.addEventListener("message", (message) => {
      onMenuChange(message);
    });
  else
    document.addEventListener("message", (message) => {
      onMenuChange(message);
    });

  if (!localStorage.getItem("nativeAudio"))
    return (
      <BottomNavigation
        className="NavBar"
        value={value}
        showLabels
        onChange={menuChange}
      >
        <BottomNavigationAction label="Inicio" icon={<HomeIcon />} />

        <BottomNavigationAction label="Buscar" icon={<Search />} />

        <BottomNavigationAction
          label="Biblioteca"
          icon={<LibraryMusicIcon />}
        />
      </BottomNavigation>
    );
}
