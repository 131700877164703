import confetti from "canvas-confetti";

export const launchConfetti = () => {
  let canvas = document.createElement("canvas");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  canvas.style.position = "fixed";
  canvas.style.top = "0";
  canvas.style.left = "0";
  canvas.style.pointerEvents = "none";
  canvas.style.zIndex = "999999";
  document.body.appendChild(canvas);

  let myConfetti = confetti.create(canvas, {
    resize: true,
    useWorker: true,
  });

  myConfetti({
    particleCount: 200,
    spread: 100,
    origin: {
      y: 0.6,
    },
  });

  setTimeout(() => {
    document.body.removeChild(canvas);
  }, 5000);
};

export const hideNavBarAndPlayer = () => {
  if (localStorage.getItem("nativeAudio")) return;
  if (window.innerWidth <= 500) {
    document.querySelector(".BottomNavBar").style.display = "none";
    document.querySelector(".bottomBarPlayer").style.display = "none";
  }
};

export const showNavBarAndPlayer = () => {
  if (localStorage.getItem("nativeAudio")) return;
  if (window.innerWidth <= 500) {
    document.querySelector(".BottomNavBar").style.display = "block";
    document.querySelector(".bottomBarPlayer").style.display = "flex";
  }
};
