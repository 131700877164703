import React from "react";
import "./Share.css";

import { ClickAwayListener } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

export default function Share(props) {
  const { open, setOpen } = props;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="Share">
        <MoreVert className="ShareIcon" onClick={() => setOpen(!open)} />
      </div>
    </ClickAwayListener>
  );
}
