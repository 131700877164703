import React from "react";
import "./CollectionItem.css";
import LazyLoad from "react-lazy-load";

import { getAuthorsString } from "../../api/audios";

export default function CollectionItem(props) {
  const { item } = props;

  return (
    <div className="collectionItem">
      <div className="collectionItemImage">
        <LazyLoad>
          <img src={item.thumbnail} alt={item.title} />
        </LazyLoad>
      </div>
      <div className="collectionItemInfo">
        <h3 className="collectionItemTitle">{item.title}</h3>
        <p className="collectionItemDescription">
          {getAuthorsString(item.authors)}
        </p>
      </div>
    </div>
  );
}
