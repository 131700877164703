import React from "react";
import "./Drawer.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Share from "@mui/icons-material/Share";
import { Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import { PlaylistAdd } from "@mui/icons-material";
import { Speed } from "@mui/icons-material";
import List from "@mui/material/List";

import { share } from "../../../utils/progressive";

export default function PlayerDrawer(props) {
  const {
    open,
    setOpen,
    item,
    isFavorite,
    handleFavorite,
    setOpenAddToPlaylist,
    reloadPlaylists,
    setOpenSpeed,
  } = props;

  const addToPlaylist = () => {
    reloadPlaylists();
    setOpenAddToPlaylist(true);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      variant="persistent"
    >
      <Box
        sx={{
          width: "100%",
          height: "max-content",
          backgroundColor: "primary.dark",
        }}
        role="presentation"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
      >
        <List
          sx={{
            padding: "10px",
            marginBottom: "20px",
            paddingTop: "20px",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <div className="DrawerItem" onClick={() => share(item, "audio")}>
            <Share sx={{ fontSize: "25px" }} />
            <span>Compartir</span>
          </div>
          <div onClick={() => handleFavorite(item)}>
            {isFavorite ? (
              <div className="DrawerItem">
                <Favorite sx={{ fontSize: "25px" }} />
                <span>Quitar de favoritos</span>
              </div>
            ) : (
              <div className="DrawerItem">
                <FavoriteBorderOutlined sx={{ fontSize: "25px" }} />
                <span>Agregar a favoritos</span>
              </div>
            )}
          </div>
          <div className="DrawerItem" onClick={() => addToPlaylist()}>
            <PlaylistAdd sx={{ fontSize: "25px" }} />
            <span>Añadir a playlist</span>
          </div>
          {item && (item.type === 2 || item.type === 3) ? (
            <div className="DrawerItem" onClick={() => setOpenSpeed(true)}>
              <Speed sx={{ fontSize: "25px" }} />
              <span>Velocidad de reproducción</span>
            </div>
          ) : null}
        </List>
      </Box>
    </Drawer>
  );
}
