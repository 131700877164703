import React, { useState } from "react";
import "./AuthForm.css";
import {
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";

import logo from "../../assets/logo.png";
import useAuth from "../../hooks/useAuth";
import { loginUser } from "../../api/auth";
import { setRefreshToken } from "../../api/token";
import { config } from "../../../src/config";
import { sendInitialData } from "../../utils/mobile";
import { saveAvatar } from "../../api/user";
import { notifyBugsnag } from "../../utils/BugsnagService";

export default function AuthForm(props) {
  const { type } = props;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      email: "",
      activationCode: "",
      password: "",
    },

    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "El email es requerido";
      }

      if (type === "activate" && !values.activationCode) {
        errors.activationCode = "El código de activación es requerido";
      }

      if (!values.password) {
        errors.password = "La contraseña es requerida";
      }

      return errors;
    },

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await loginUser(values.email, values.password);
        if (response.access_token) {
          setRefreshToken(response.refresh_token);
          login(response.access_token);
          sendInitialData();
          saveAvatar(response.user.avatar);
          if (searchParams.get("t")) {
            const id = searchParams.get("t");
            setSearchParams({ t: undefined });
            navigate(`/listen?t=${id}`);
          } else {
            navigate("/");
          }
        } else {
          alert("Usuario o contraseña incorrectos");
        }
      } catch (error) {
        console.log(error);
        notifyBugsnag(error);
      }
      setLoading(false);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="LoginForm">
        <img src={logo} alt="Logo" className="LoginForm-logo" />
        <h2 className="loginTitle">
          {type === "login" ? "Iniciar sesión" : "Activar cuenta"}
        </h2>
        <div className="loginSubline"></div>
        <TextField
          id="outlined-basic"
          name="email"
          label="Email o usuario"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          sx={{
            width: "300px",
            marginBottom: "10px",
          }}
        />
        <TextField
          id="outlined-basic"
          name="password"
          label="Contraseña"
          type={showPassword ? "text" : "password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          sx={{
            width: "300px",
            marginBottom: "10px",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            width: "300px",
            backgroundColor: "primary.contrast",
            color: "white",
          }}
        >
          {loading ? (
            <CircularProgress size={20} sx={{ color: "primary.main" }} />
          ) : type === "login" ? (
            "Ingresar"
          ) : (
            "Activar"
          )}
        </Button>
      </form>
      <div className="forgot">
        <p>
          ¿Olvidaste tu contraseña?{" "}
          <a
            href={config.miibForgotPasswordUrl}
            target="_blank"
            rel="noreferrer"
          >
            Recuperar con miib
          </a>
        </p>
      </div>
      <div className="copyrigth">
        <p>Iglesia Ministerio Levantando a Cristo © </p>
      </div>
    </div>
  );
}
