import React, { useState, useEffect } from "react";
import "./SearchText.css";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import useSearch from "../../hooks/useSearch";
import { getAllTitlesFromLocalStorage } from "../../api/audios";
import { hideNavBarAndPlayer, showNavBarAndPlayer } from "../../utils/ux";

export default function SearchText() {
  const { showSearch, setShowSearch } = useSearch();
  const isSearchRoute = window.location.pathname === "/search";
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  console.log(setSearchParams);

  useEffect(() => {
    setOptions(getAllTitlesFromLocalStorage());
    if (isSearchRoute) {
      const query = searchParams.get("query");
      console.log(query);
      if (query) {
        setSearchText(query);
      }
    }
  }, [isSearchRoute, searchParams]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const leaveSearch = () => {
    if (!isSearchRoute) {
      setShowSearch(false);
    }
    showNavBarAndPlayer();
  };

  const handleSearchTextWidth = () => {
    if (screenWidth >= 500) {
      return "50%";
    } else if (screenWidth <= 500 && !isSearchRoute) {
      return "100%";
    } else if (screenWidth < 500 && isSearchRoute) {
      return "70%";
    }
  };

  return showSearch ? (
    <div className="SearchText">
      <Autocomplete
        freeSolo
        disablePortal
        id="combo-box-demo"
        forcePopupIcon={false}
        options={options}
        value={isSearchRoute && searchText ? searchText : "" || null}
        PaperComponent={({ children }) => (
          <Paper
            elevation={0}
            sx={{
              backgroundColor: "#161a35",
              fontSize: 16,
            }}
          >
            {children}
          </Paper>
        )}
        className="SearchTextInput"
        noOptionsText="No hay resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={() => hideNavBarAndPlayer()}
            label="¿Qué quieres escuchar?"
            onBlur={leaveSearch}
          />
        )}
        sx={{
          width: handleSearchTextWidth(),
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            navigate("/search?query=" + newValue.label);
          }
        }}
        onInputChange={(event, newInputValue) => {
          if (newInputValue) {
            setSearchText(newInputValue);
          }
        }}
      />
    </div>
  ) : null;
}
