import React from "react";
import "./Privacy.css";

export default function Privacy() {
  return (
    <div className="Privacy View">
      <div className="Privacy-header">
        <h2>Privacidad</h2>
      </div>
      <div className="Privacy-body">
        <div className="Privacy-item">
          <h3>¿Qué datos recopilamos?</h3>
          <p>
            Cuando el usuario se registra en Rhema, se le pide una cantidad
            limitada de información, como el nombre, nombre de usuario,
            contraseña y dirección de correo electrónico. Es posible que en el
            futuro se le pidan más.
            <br />
            <br />
            El nombre de usuario que se proporcione, será mostrado públicamente
            en el perfil del usuario. Nosotros nunca compartiremos tu
            información personal con gente externa a la red discipular de la
            Iglesia Ministerio Levantando A Cristo
          </p>
        </div>
        <div className="Privacy-item">
          <h3>Vigencia</h3>
          <p>
            Podrás hacer uso de los servicios de Rhema mientras estés vinculado
            a la red discipular de la Iglesia Ministerio Levantando a Cristo y a
            su vez seas un usuario habilitado dentro de la aplicación.
            <br />
            <br />
            Los servicios que Rhema proporciona están siempre evolucionando. La
            forma y la naturaleza de dichos servicios podrá cambiar
            eventualmente sin previo aviso.
            <br />
            <br />
            Además, Rhema podrá cesar (permanente o temporalmente) de proveer
            estos servicios (o cualquier artículo de estos Servicios) al usuario
            y/o usuarios con o sin previo aviso. Rhema se reserva el derecho de
            limitar el uso o almacenamiento bajo su única discreción en
            cualquier momento sin previo aviso.
          </p>
        </div>
        <h4>Pastor Gonzalo Maturana Aguilar</h4>
      </div>
    </div>
  );
}
