import React from "react";
import "../../../components/Player/Drawer/Drawer.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { DeleteOutline } from "@mui/icons-material";
import List from "@mui/material/List";
import { paddingBottom } from "../../../utils/mobile";
import {
  updatePlaylist,
  deleteAudioFromPlaylistFromLocalStorage,
} from "../../../api/playlists";
import { notify } from "../../../App";

export default function ItemDrawer(props) {
  const { open, setOpen, playlist, optionItem, refresh, setRefresh } = props;

  const deleteItem = async () => {
    deleteAudioFromPlaylistFromLocalStorage(playlist, optionItem);
    setRefresh(!refresh);
    await updatePlaylist({
      id: playlist.id,
      title: playlist.title,
      audios: playlist.audios.filter((audio) => audio.id !== optionItem.id),
    });
    notify("Eliminado de la playlist");
    setOpen(false);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      variant="persistent"
    >
      <Box
        sx={{
          width: "100%",
          height: "max-content",
          backgroundColor: "primary.dark",
        }}
        role="presentation"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
      >
        <List
          sx={{
            padding: "10px",
            marginBottom: "20px",
            paddingTop: "20px",
            transition: "all 0.5s ease-in-out",
            paddingBottom: paddingBottom,
          }}
        >
          <div className="DrawerItem" onClick={deleteItem}>
            <DeleteOutline sx={{ fontSize: "25px" }} />
            <span>Eliminar de la playlist</span>
          </div>
        </List>
      </Box>
    </Drawer>
  );
}
