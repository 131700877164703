import React from "react";
import "./UploadResult.css";
import { Modal, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { messages } from "../../../utils/ux-writing";

export default function UploadResult(props) {
  const { open, setOpen, ok } = props;

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "#161833",
          width: window.innerWidth > 570 ? "500px" : "90%",
          height: "300px",
          margin: "auto",
          marginTop: "10%",
          padding: "1px 10px 0px 10px",
        }}
      >
        <div className="uploadResult">
          <h3>{ok ? messages.successTitle : messages.errorTitle}</h3>
          <div className="icon">
            {ok ? (
              <CheckCircleIcon sx={{ fontSize: 100, color: "#00FF00" }} />
            ) : (
              <CancelIcon sx={{ fontSize: 100, color: "#FF4F49" }} />
            )}
          </div>
          <p className="resultMsg">
            {ok ? messages.successMessage : messages.errorMessage}
          </p>
        </div>
      </Box>
    </Modal>
  );
}
