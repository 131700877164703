export function Capitalize(str) {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function CapitalizeAll(str) {
  return str
    .split(" ")
    .map((word) => Capitalize(word))
    .join(" ");
}

export function NumberToBytesUnits(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB"];
  if (bytes === 0) return "0 Bytes";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function secondsToMinutes(seconds) {
  if (isNaN(seconds)) {
    return "0:00";
  }

  seconds = parseInt(seconds);

  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds - minutes * 60;

  if (minutes > 59) {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes - hours * 60;
    return `${hours}:${minutesLeft < 10 ? "0" : ""}${minutesLeft}:${
      secondsLeft < 10 ? "0" : ""
    }${secondsLeft}`;
  }

  return `${minutes}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`;
}

export function getElementsQuantity(elements) {
  if (elements == null || elements.length === 0) {
    return "0 elementos";
  }
  if (elements.length === 1) {
    return "1 elemento";
  }

  return `${elements.length} elementos`;
}

export function getElementsQuantityWithType(elements, type) {
  if (elements == null || elements.length === 0) {
    return "0 elementos";
  }

  let songs = 0;
  let chapters = 0;
  let series = 0;

  elements.forEach((element) => {
    if (element.type === 1) {
      songs++;
    } else if (element.type === 2) {
      chapters++;
    } else if (element.type === 3) {
      series++;
    }
  });

  if (type === 1) {
    if (songs === 1) {
      return "1 canción";
    }
    return `${songs} canciones`;
  } else if (type === 2) {
    if (chapters === 1) {
      return "1 capítulo";
    }
    return `${chapters} capítulos`;
  } else if (type === 3) {
    if (series === 1) {
      return "1 predicación";
    }
    return `${series} predicaciones`;
  }
}

export function getCollectionType(type = 1) {
  if (type === 1) {
    return "Álbum";
  }
  if (type === 2) {
    return "Podcast";
  }

  return "Serie";
}

export function convertHhMmSsToSeconds(time) {
  const parts = time.split(":");
  let seconds = 0;
  let minutes = 1;

  while (parts.length > 0) {
    seconds += minutes * parseInt(parts.pop(), 10);
    minutes *= 60;
  }

  return seconds;
}
