import React, { useState, useEffect } from "react";
import "./Collection.css";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Skeleton } from "@mui/material";
import {
  Favorite,
  FavoriteBorder,
  PlayArrow,
  Share,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getCollection } from "../../api/collections";
import {
  addCollectionToFavorites,
  removeCollectionFromFavorites,
  verifyIfIsFavorite,
} from "../../api/favorites";
import usePlayer from "../../hooks/usePlayer";
import { share } from "../../utils/progressive";
import { notify } from "../../App";
import { getAuthorsString } from "../../api/audios";
import {
  getCollectionType,
  getElementsQuantityWithType,
} from "../../utils/strings";
import SortChips from "./SortChips/SortChips";

const SERMONS_SERIE_TYPE = 3;

export default function Collection() {
  const collectionId = window.location.pathname.split("/")[2];
  const [collection, setCollection] = useState({});
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([1, 2, 3, 4, 5]);
  const [isFavorite, setIsFavorite] = useState(false);
  const navigate = useNavigate();
  const [sort, setSort] = useState("newest");
  const [isSermonsSeries, setIsSermonsSeries] = useState(false);
  const [collectionClassName, setCollectionClassName] =
    useState("CollectionItemInfo");

  const {
    audio: currentAudio,
    setQueue,
    setPlayingACollection,
    setCollection: setPlayerCollection,
    setIsPlaying,
    setAudio,
    setShowPlayer,
    setCollectionId,
  } = usePlayer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getCollection(collectionId).then((res) => {
      setCollection(res.data);
      let items = [];
      if (collection.type === SERMONS_SERIE_TYPE) {
        items = res.data.audios.slice().reverse();
        setIsSermonsSeries(true);
        setCollectionClassName("CollectionItemInfo SerieItemInfo");
      } else {
        items = res.data.audios;
      }
      setItems(items);
      setLoading(false);
    });
    const isFavorite = verifyIfIsFavorite(collectionId, "collections");
    setIsFavorite(isFavorite);
  }, [collectionId, collection.type]);

  const playCollectionItem = (audio) => {
    localStorage.setItem("tracktimePosition", 0);
    setPlayerCollection(collection.audios);
    setPlayingACollection(true);
    setCollectionId(collectionId);
    setAudio(audio);
    setIsPlaying(true);
    setShowPlayer(true);
    setQueue([audio]);
  };

  const handleFavorite = () => {
    setIsFavorite(!isFavorite);
    if (!isFavorite) {
      addCollectionToFavorites(collection);
      notify("Añadido a tus favoritos");
    } else {
      removeCollectionFromFavorites(collection);
      notify("Eliminado de tus favoritos");
    }
  };

  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const getCollectionItemClass = (id) => {
    let className = "CollectionItem";
    if (currentAudio && currentAudio.id === id) {
      className += " playing";
    }
    return className;
  };

  const onChangeSort = (value) => {
    if (value === sort) return;
    setSort(value);
    setItems(items.reverse());
  };

  return (
    <div className="View Collection">
      <div className="ViewHeader CollHeader" style={{ marginTop: "1rem" }}>
        <div className="LinkArrowBack" onClick={() => goBack()}>
          <ArrowBackIcon sx={{ fontSize: 30 }} />
        </div>
        <div className="collectionDataHeader">
          <div className="collectionData"></div>
          <div className="collectionActions">
            <div
              onClick={() => share(collection, "collection")}
              className="Share"
            >
              <Share sx={{ fontSize: 30, marginRight: "10px" }} />
            </div>
            {isFavorite ? (
              <div className="Favorite" onClick={handleFavorite}>
                <Favorite sx={{ fontSize: 30 }} />
              </div>
            ) : (
              <div className="Favorite" onClick={handleFavorite}>
                <FavoriteBorder sx={{ fontSize: 30 }} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="CollectionContent">
        <div className="CollectionInfo">
          <div className="CollectionImage">
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={100}
                height={100}
                animation="wave"
                sx={{ marginRight: "0.5rem", marginBottom: "1rem" }}
              />
            ) : (
              <img src={collection.thumbnail} alt={collection.name} />
            )}
          </div>
          <div className="CollectionData">
            <div className="CollectionName">
              {loading ? (
                <Skeleton
                  variant="text"
                  width={200}
                  height={20}
                  animation="wave"
                />
              ) : (
                collection.title
              )}
            </div>
            <div className="CollectionAuthor">
              {loading ? (
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  animation="wave"
                />
              ) : (
                getAuthorsString(collection.authors)
              )}
            </div>
            <div className="CollectionDescription">
              {loading ? (
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  animation="wave"
                />
              ) : (
                getCollectionType(collection.type) +
                " - " +
                getElementsQuantityWithType(collection.audios, collection.type)
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ margin: "1rem 0 1rem 0", width: "100%", height: "35px" }}
          />
        ) : (
          <Button
            variant="contained"
            onClick={() => playCollectionItem(collection.audios[0])}
            sx={{
              width: "100%",
              margin: "1rem 0 0 0",
              backgroundColor: "white",
              color: "primary.dark",
              "&:hover": {
                backgroundColor: "primary.main",
              },
            }}
          >
            <PlayArrow sx={{ fontSize: 20, marginRight: "10px" }} />
            Reproducir
          </Button>
        )}
      </div>
      <div className="CollectionItems">
        {isSermonsSeries && <SortChips sort={sort} callback={onChangeSort} />}
        {loading
          ? items.map((item, index) => (
              <div className="CollectionItem" key={index}>
                <div className="skeleton">
                  <Skeleton width={200} />
                  <Skeleton width={100} sx={{ marginBottom: "20px" }} />
                </div>
              </div>
            ))
          : items.map((item, index) => (
              <div
                onClick={() => playCollectionItem(item)}
                className={getCollectionItemClass(item.id)}
                key={index}
              >
                {collection.type !== SERMONS_SERIE_TYPE && (
                  <p className="number">{index + 1}</p>
                )}
                <div className={collectionClassName}>
                  <h4 className="CollectionItemTitle">{item.title}</h4>
                  <p className="CollectionItemAuthor">
                    {getAuthorsString(item.authors)} - {item.duration}
                  </p>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}
