import { getAccessToken } from "../api/token";
import { getUserData } from "../api/user";
import {
  addAudioToFavorites,
  removeAudioFromFavorites,
} from "../api/favorites";
import { getAudio } from "../api/audios";
import {
  addAudioToPlaylist,
  createPlaylist,
  getMyPlaylists,
} from "../api/playlists";
import { notifyBugsnag } from "../utils/BugsnagService";

const sendPostMessage = (message) => {
  if (!window.ReactNativeWebView) return;
  const stringifyMessage = JSON.stringify(message);
  window.ReactNativeWebView.postMessage(stringifyMessage);
};

const addToFavorites = async (audioId) => {
  try {
    const audio = await getAudio(audioId);
    await addAudioToFavorites(audio.data);
    const message = {
      type: "favorite-added",
      audio: audio.data,
    };
    sendPostMessage(message);
  } catch (error) {
    dispatchError();
    notifyBugsnag(error);
  }
};

const removeFromFavorites = async (audioId) => {
  try {
    const audio = await getAudio(audioId);
    await removeAudioFromFavorites(audio.data);
    const message = {
      type: "favorite-removed",
      audio: audio.data,
    };
    sendPostMessage(message);
  } catch (error) {
    dispatchError();
    notifyBugsnag(error);
  }
};

const dispatchError = () => {
  const message = {
    type: "error",
  };
  sendPostMessage(message);
};

export const sendPlaylist = () => {
  const playlists = localStorage.getItem("playlists");
  const message = {
    type: "playlists",
    playlists: playlists,
  };
  sendPostMessage(message);
};

const AddToPlaylist = async (data) => {
  try {
    const playlist = JSON.parse(data.playlist);
    const audio = await getAudio(data.audioId);
    await addAudioToPlaylist(playlist, audio.data);
    const playlists = localStorage.getItem("playlists");
    const message = {
      type: "audio-added",
      playlists: playlists,
    };
    sendPostMessage(message);
  } catch (err) {
    const message = {
      type: "error",
    };
    notifyBugsnag(err);
    sendPostMessage(message);
  }
};

const NewPlaylist = async (data) => {
  try {
    const audio = await getAudio(data.audioId);
    await createPlaylist(data.title, [audio.data]);
    localStorage.removeItem("lastPlaylistsRefresh");
    await getMyPlaylists();
    const playlists = localStorage.getItem("playlists");
    const message = {
      type: "audio-added",
      playlists: playlists,
    };
    sendPostMessage(message);
  } catch (err) {
    const message = {
      type: "error",
    };
    notifyBugsnag(err);
    sendPostMessage(message);
  }
};

const handleMessage = async (message) => {
  const data = JSON.parse(message.data);
  switch (data.action) {
    case "add-favorite":
      await addToFavorites(data.audioId);
      break;
    case "remove-favorite":
      await removeFromFavorites(data.audioId);
      break;
    case "add-to-playlist":
      AddToPlaylist(data);
      break;
    case "new-playlist":
      NewPlaylist(data);
      break;
    case "back-action":
      sendPostMessage({ type: "back-action", path: window.location.pathname });
      break;
    case "request-initial-data":
      sendInitialData();
      break;
    // no default
  }
};

const startMessageListener = () => {
  console.log("Starting message listener");
  if (navigator.platform === "iPhone")
    window.addEventListener("message", (message) => {
      handleMessage(message);
    });
  else
    document.addEventListener("message", (message) => {
      handleMessage(message);
    });
};

export const sendPlayRequest = (collectionId, collection, audio) => {
  let message = {};
  let currentAudioIndex = 0;
  if (!audio) return;
  if (collectionId !== null && collection !== null) {
    message.isCollection = true;
    message.audioData = collection;
    currentAudioIndex = collection.findIndex((el) => {
      return el === audio;
    });
  } else {
    message.isCollection = false;
    message.audioData = [audio];
  }
  message.accessToken = getAccessToken();
  message.currentAudioIndex = currentAudioIndex;
  message.type = "play";
  sendPostMessage(message);
};

const sendAccessToken = () => {
  const message = {
    type: "access-token",
    token: getAccessToken(),
    userInfo: getUserData(),
  };
  sendPostMessage(message);
};

export const sendLogoutRequest = () => {
  const message = {
    type: "logout",
  };
  sendPostMessage(message);
};

export const sendFavorites = () => {
  const favorites = localStorage.getItem("favoritesAudios");
  if (!favorites) return;
  const message = {
    type: "favorites",
    favorites: favorites,
  };
  sendPostMessage(message);
};

export const sendInitialData = () => {
  if (!getAccessToken()) return;
  sendAccessToken();
  sendFavorites();
  sendPlaylist();
};

export const paddingBottom = () => {
  return window.ReactNativeWebView ? "100px" : "0px";
};

export const sendExternalLink = (link) => {
  if (!link) return;
  const message = {
    type: "external-link",
    link: link,
  };
  sendPostMessage(message);
};

export const sendCurrentLocation = (location) => {
  if (!window.ReactNativeWebView || !location) return;
  const message = {
    type: "current-location",
    location: location,
  };
  sendPostMessage(message);
};

window.addEventListener("load", () => {
  if (!window.ReactNativeWebView) return;
  startMessageListener();
  // TODO: remove after v1.6.2
  sendInitialData();
});
