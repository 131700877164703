export const tagsColors = [
  "#F49159",
  "#37BFDD",
  "#57D362",
  "#FB7B7B",
  "#764DEB",
  "#6E97FF",
  "#F987F2",
  "#6ADFA1",
];

export const pastorsColors = ["#FC9518", "#262EF2"];
