import React from "react";
import "./AddToPlaylist.css";
import { Button, Dialog } from "@mui/material";
import Playlists from "../../Playlists/Playlists";

import { addAudioToPlaylist } from "../../../api/playlists";
import { Link } from "react-router-dom";

export default function AddToPlaylist(props) {
  const { open, setOpen, item, setFullScreen } = props;
  const { playlists } = props;

  const addToPlaylist = async (playlist) => {
    await addAudioToPlaylist(playlist, item);
    setOpen(false);
  };

  const goToCreatePlaylist = () => {
    setOpen(false);
    setFullScreen(false);
    localStorage.setItem("selectedAudio", JSON.stringify(item));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} sx={{ zIndex: 2000 }}>
      <div className="AddToPlaylist">
        {playlists.length > 0 ? (
          <Playlists
            playlists={playlists}
            addToPlaylist={addToPlaylist}
            audio={item}
            origin="player"
          />
        ) : (
          <div className="no-playlists">
            <p>No tienes playlists</p>
            <Link
              to="/library?create=playlist"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "primary.contrast",
                  color: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.main",
                    color: "primary.dark",
                  },
                }}
                onClick={() => goToCreatePlaylist()}
              >
                Crear
              </Button>
            </Link>
          </div>
        )}
      </div>
    </Dialog>
  );
}
