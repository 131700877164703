import React from "react";
import "./FavoriteAudios.css";
import { Favorite } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function FavoriteAudios(props) {
  const { audios } = props;
  const navigate = useNavigate();
  return (
    <div className="favorite-audios" onClick={() => navigate("/favorites")}>
      {audios.length > 0 ? (
        <>
          <div className="favorite-audios-container">
            <Favorite sx={{ fontSize: 40 }} />
          </div>
          <div className="favorite-audios-title">
            <h3>Tus favoritos</h3>
            <p>
              {audios.length} {audios.length > 1 ? "elementos" : "elemento"}
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
}
