import React, { useState, useEffect } from "react";
import "./ItemsGallery.css";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";

import { getAuthorsString } from "../../api/audios";
import usePlayer from "../../hooks/usePlayer";

export default function ItemsGallery(props) {
  const [items, setItems] = useState([1, 2, 3, 4, 5]);
  const [loadedImages, setLoadedImages] = useState(false);
  const { loading } = props;
  const navigate = useNavigate();

  const {
    // showPlayer,
    setShowPlayer,
    // audio,
    setAudio,
    // isPlaying,
    setIsPlaying,
    // volume,
    // setVolume,
    // queue,
    // setQueue,
    setCollectionId,
  } = usePlayer();

  /* eslint-disable */
  useEffect(() => {
    setItems(props.items);
  }, [props.type]);
  /* eslint-enable */

  const openItem = (item) => {
    localStorage.setItem("tracktimePosition", 0);
    if (item.url) {
      setAudio(item);
      setIsPlaying(true);
      setShowPlayer(true);
      setCollectionId(null);
    } else {
      navigate("/collection/" + item.id);
    }
  };

  return (
    <div className="ItemsGallery">
      {loading ? (
        <>
          <Skeleton
            sx={{
              bgcolor: "blueGrey.500",
              marginBottom: "10px",
              marginTop: "10px",
            }}
            width={200}
            height={30}
            animation="wave"
          />
          <div className="loading">
            {items.map((item, index) => {
              return (
                <div className="skeleton" key={index}>
                  <Skeleton
                    sx={{
                      bgcolor: "blueGrey.500",
                      marginBottom: "10px",
                      borderRadius: "10px",
                    }}
                    variant="rectangular"
                    width={window.innerWidth > 600 ? 200 : 150}
                    height={window.innerWidth > 600 ? 200 : 150}
                    animation="wave"
                  />
                  <Skeleton sx={{ bgcolor: "blueGrey.500" }} animation="wave" />
                  <Skeleton
                    sx={{ bgcolor: "blueGrey.500" }}
                    width="60%"
                    animation="wave"
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <h2>{props.title}</h2>
          {
            <div className="items Horizontal">
              {items.map((item, index) => {
                return (
                  <div
                    className="item"
                    key={index}
                    onClick={() => {
                      openItem(item);
                    }}
                  >
                    <LazyLoad onContentVisible={() => setLoadedImages(true)}>
                      {loadedImages ? (
                        <img
                          src={item.thumbnail}
                          alt={item.title}
                          className="img-item"
                        />
                      ) : (
                        <div className="skeleton">
                          <Skeleton
                            sx={{
                              bgcolor: "blueGrey.500",
                              borderRadius: "10px",
                            }}
                            variant="rectangular"
                            width={window.innerWidth > 600 ? 200 : 150}
                            height={window.innerWidth > 600 ? 200 : 150}
                            animation="wave"
                          />
                        </div>
                      )}
                    </LazyLoad>
                    <div className="item-info">
                      <p className="item-title">{item.title}</p>
                      <p className="item-author">
                        {getAuthorsString(item.authors)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        </>
      )}
    </div>
  );
}
