import React, { useState, useEffect } from "react";
import "./SearchResults.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SearchText from "../../components/SearchText/SearchText";
import useSearch from "../../hooks/useSearch";
import CollectionItem from "../../components/CollectionItem/CollectionItem";
import usePlayer from "../../hooks/usePlayer";
import { getTags } from "../../api/tags";
import DiscoverWidget from "../../components/DiscoverWidget/DiscoverWidget";
import { tagsColors, pastorsColors } from "../../utils/tags";
import { getPastors } from "../../api/authors";
import prgonzaloWidget from "../../assets/images/pastors/pr-gonzalo-widget.svg";
import prjlWidget from "../../assets/images/pastors/pr-jl-widget.svg";

const { getSearchResults } = require("../../api/search");

export default function SearchResults(props) {
  const { showSearch, setShowSearch } = useSearch();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pastors, setPastors] = useState([]);
  const [tags, setTags] = useState([]);
  const {
    // showPlayer,
    setShowPlayer,
    // audio,
    setAudio,
    // isPlaying,
    setIsPlaying,
    // volume,
    // setVolume,
    // queue,
    // setQueue,
  } = usePlayer();
  const navigate = useNavigate();
  console.log("searchResults", setSearchParams, showSearch);

  const pastorsImages = [prgonzaloWidget, prjlWidget];
  const pastorsCollectionsIDs = [
    "fFvsBdg1mKeAJMOMbkFF",
    "phmz4GaH0edF6pEZpkq8",
  ];

  /* eslint-disable */
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowSearch(true);
  }, []);
  /* eslint-enable */

  useEffect(() => {
    getSearchResults(query).then((res) => {
      setSearchResults(res.data);
      setLoading(false);
    });
  }, [query]);

  useEffect(() => {
    getTags().then((res) => {
      setTags(res);
    });
    getPastors().then((res) => {
      setPastors(sortPastors(res));
    });
  }, []);

  const openItem = (item) => {
    localStorage.setItem("tracktimePosition", 0);
    if (item.url) {
      setAudio(item);
      setIsPlaying(true);
      setShowPlayer(true);
    } else {
      navigate("/collection/" + item.id);
    }
  };

  const goBack = () => {
    setShowSearch(false);
    if (window.history.length > 1 && window.location.pathname !== "/search") {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  // This function is used to force that pastors are always shown in the same order
  const sortPastors = (pastorsArray) => {
    const gonzalo = pastorsArray.find((pastor) =>
      pastor.name.includes("Gonzalo")
    );
    const jl = pastorsArray.find((pastor) => pastor.name.includes("José Luis"));

    return [gonzalo, jl];
  };

  return (
    <div className="Library View">
      <div className="ViewHeader SearchHeader">
        <div className="ArrowBack">
          <ArrowBackIcon onClick={goBack} />
        </div>

        <SearchText />
      </div>
      <div className="SearchResults">
        {loading ? (
          <div className="Loading">
            <CircularProgress />
          </div>
        ) : searchResults.length === 0 ? (
          <div className="Discover">
            <h2>Pastores</h2>
            <div className="DiscoverTags">
              {pastors.map((pastor, index) => {
                return (
                  <DiscoverWidget
                    tagName={pastor.name}
                    bgColor={pastorsColors[index]}
                    key={index}
                    tagId={pastor.id}
                    isPastor={true}
                    img={pastorsImages[index]}
                    collectionId={pastorsCollectionsIDs[index]}
                  />
                );
              })}
            </div>
            <h2>Categorías</h2>
            <div className="DiscoverTags">
              {tags.map((tag, index) => {
                return (
                  <DiscoverWidget
                    tagName={tag.name}
                    bgColor={tagsColors[index]}
                    key={index}
                    tagId={tag.id}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          searchResults.map((item, index) => {
            return (
              <div
                className="SearchResult"
                key={index}
                onClick={() => openItem(item)}
              >
                <CollectionItem item={item} />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
