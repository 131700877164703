import React from "react";
import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";

export default function CreatePlaylist(props) {
  const { showPlayer, setOpen, open } = props;

  const bottomValue = () => {
    if (window.ReactNativeWebView) return "130px";
    if (showPlayer) return "160px";
    return "80px";
  };

  return (
    <Fab
      sx={{
        position: "fixed",
        bottom: bottomValue(),
        right: 20,
        zIndex: 1,
        backgroundColor: "primary.contrast",
        color: "primary.main",
        visibility: open ? "hidden" : "visible",
        "&:hover": {
          color: "primary.contrast",
        },
      }}
      onClick={() => setOpen(true)}
    >
      <Add />
    </Fab>
  );
}
