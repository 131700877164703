export const messages = {
  successTitle: "¡Gracias por ayudarnos a mejorar!",
  successMessage: "Tu solicitud ha sido enviada con éxito.",

  errorTitle: "¡Ups! Algo salió mal",
  errorMessage:
    "Hubo un problema con la carga de tu solicitud. Por favor, intenta nuevamente más tarde.",
  errorSharing:
    "La funcionalidad de compartir no está disponible en tu dispositivo",

  successPlaylist: "¡Playlist creada con éxito!",
  successPlaylistMessage: "Ya puedes agregar canciones a tu playlist",
};
