import axios from "axios"

import { config } from "../config"
import { getAccessToken } from "./token"
import { getUserData } from "./user"

export const createIssue = (summary, description) => {
    const issueData = {
        device: navigator.userAgent,
        user: getUserData(),
        date: new Date()
    }
    return axios.post(config.coreApiUrl + "/issues", {
        summary: summary,
        description: description +"\n\nUsuario: " + issueData.user?.username + " - Email: " + issueData.user?.email + "\nDispositivo: " + issueData.device + "\nFecha: " + issueData.date
    }, {
        headers: {
            "Content-Type": "application/json",
            Authorization: getAccessToken()
        }
    })
}