import React, { useEffect, useState } from "react";
import "./Library.css";
import { useSearchParams } from "react-router-dom";

import Header from "../../components/Header/Header";
import Playlists from "../../components/Playlists/Playlists";
import FavoriteAudios from "../../components/FavoriteAudios/FavoriteAudios";
import FavoriteCollections from "../../components/FavoriteCollections/FavoriteCollections";
import CreatePlaylist from "../../components/CreatePlaylist/CreatePlaylist";
import CreatePlaylistDialog from "../../components/Modals/CreatePlaylistDialog/CreatePlaylistDialog";
import PlaylistCreated from "../../components/Modals/PlaylistCreated/PlaylistCreated";
import { handleLocalStorageData } from "../../api/favorites";
import useSearch from "../../hooks/useSearch";

export default function Library(props) {
  const [favoritesAudios, setFavoritesAudios] = useState([]);
  const [favoritesCollections, setFavoritesCollections] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const { showPlayer } = props;
  const { setShowSearch } = useSearch();
  const [searchParams, setSearchParams] = useSearchParams();

  /* eslint-disable */
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowSearch(false);
    if (searchParams.get("create") === "playlist") {
      console.log(setSearchParams);
      setOpen(true);
    }
    setFavoritesAudios(handleLocalStorageData("favoritesAudios"));
    setFavoritesCollections(handleLocalStorageData("favoritesCollections"));
    setPlaylists(handleLocalStorageData("playlists"));
  }, []);
  /* eslint-enable */

  return (
    <div className="Library View">
      <Header />
      <div className="library-content">
        <h2>Tu biblioteca</h2>
        {playlists.length === 0 &&
        favoritesAudios.length === 0 &&
        favoritesCollections.length === 0 ? (
          <p>Tu biblioteca está vacía</p>
        ) : null}
        <FavoriteAudios audios={favoritesAudios} />
        <FavoriteCollections collections={favoritesCollections} />
        <Playlists playlists={playlists} />
        <CreatePlaylist showPlayer={showPlayer} setOpen={setOpen} open={open} />
      </div>
      <CreatePlaylistDialog
        open={open}
        setOpen={setOpen}
        setPlaylists={setPlaylists}
        setOpenSuccess={setOpenSuccess}
      />
      <PlaylistCreated open={openSuccess} setOpen={setOpenSuccess} />
    </div>
  );
}
