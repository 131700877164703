import axios from "axios";
import { config } from "../config";
import { getAccessToken } from "./token";
import { sendFavorites } from "../utils/mobile";
import { notifyBugsnag } from "../utils/BugsnagService";

export const getFavoritesByEmail = async () => {
  const lastRefresh = localStorage.getItem("lastFavoritesRefresh");
  const now = new Date().getTime();
  const twentyFourHours = 24 * 60 * 60 * 1000;

  if ((lastRefresh && now - lastRefresh > twentyFourHours) || !lastRefresh) {
    try {
      const res = await axios.get(`${config.coreApiUrl}/likes`, {
        headers: {
          Authorization: getAccessToken(),
        },
      });

      if (res.data.audios === null || res.data.audios === undefined) {
        console.log("No hay audios favoritos");
        localStorage.setItem("favoritesAudios", JSON.stringify([]));
      } else {
        localStorage.setItem(
          "favoritesAudios",
          JSON.stringify(res.data.audios)
        );
      }

      if (res.data.collections === null || res.data.collections === undefined) {
        console.log("No hay colecciones favoritas");
        localStorage.setItem("favoritesCollections", JSON.stringify([]));
      } else {
        localStorage.setItem(
          "favoritesCollections",
          JSON.stringify(res.data.collections)
        );
      }

      localStorage.setItem("lastFavoritesRefresh", now);
      sendFavorites();
    } catch (error) {
      console.log(error);
      notifyBugsnag(error);
      localStorage.setItem("favoritesAudios", JSON.stringify([]));
      localStorage.setItem("favoritesCollections", JSON.stringify([]));
    }
  }
};

export const verifyIfIsFavorite = (id, type) => {
  let favorites;
  if (type === "audios") {
    favorites = localStorage.getItem("favoritesAudios");
  } else if (type === "collections") {
    favorites = localStorage.getItem("favoritesCollections");
  }

  if (
    favorites !== undefined ||
    favorites !== "undefined" ||
    favorites !== null
  ) {
    favorites = JSON.parse(favorites);
    const favorite = favorites.find((favorite) => favorite.id === id);
    if (favorite) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const addAudioToFavorites = async (audio) => {
  let favoritesAudios = localStorage.getItem("favoritesAudios");
  if (favoritesAudios) {
    favoritesAudios = JSON.parse(favoritesAudios);
    const isAlreadyFavorite = verifyIfIsFavorite(audio.id, "audios");
    if (!isAlreadyFavorite) {
      favoritesAudios.push(audio);
      localStorage.setItem("favoritesAudios", JSON.stringify(favoritesAudios));
    }
  } else {
    favoritesAudios = [audio];
    localStorage.setItem("favoritesAudios", JSON.stringify(favoritesAudios));
  }
  console.log(favoritesAudios);
  const collections = await handleLocalStorageData("favoritesCollections");
  const response = await axios.put(
    `${config.coreApiUrl}/likes`,
    {
      audios: favoritesAudios,
      collections,
    },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    }
  );
  return response.data;
};

export const removeAudioFromFavorites = async (audio) => {
  let favoritesAudios = localStorage.getItem("favoritesAudios");
  if (favoritesAudios) {
    favoritesAudios = JSON.parse(favoritesAudios);
    const audioAlreadyInFavorites = verifyIfIsFavorite(audio.id, "audios");
    if (audioAlreadyInFavorites) {
      const newFavoritesAudios = favoritesAudios.filter(
        (favorite) => favorite.id !== audio.id
      );
      localStorage.setItem(
        "favoritesAudios",
        JSON.stringify(newFavoritesAudios)
      );

      const collections = await handleLocalStorageData("favoritesCollections");

      const response = await axios.put(
        `${config.coreApiUrl}/likes`,
        {
          audios: newFavoritesAudios,
          collections,
        },
        {
          headers: {
            Authorization: getAccessToken(),
          },
        }
      );

      return response.data;
    }
  } else {
    return;
  }
};

export const addCollectionToFavorites = async (collection) => {
  let favoritesCollections = localStorage.getItem("favoritesCollections");
  if (favoritesCollections) {
    favoritesCollections = JSON.parse(favoritesCollections);
    const isAlreadyFavorite = verifyIfIsFavorite(collection.id, "collections");
    if (!isAlreadyFavorite) {
      favoritesCollections.push(collection);
      localStorage.setItem(
        "favoritesCollections",
        JSON.stringify(favoritesCollections)
      );
    }
  } else {
    favoritesCollections = [collection];
    localStorage.setItem(
      "favoritesCollections",
      JSON.stringify(favoritesCollections)
    );
  }
  const audios = await handleLocalStorageData("favoritesAudios");

  const response = await axios.put(
    `${config.coreApiUrl}/likes`,
    {
      audios,
      collections: favoritesCollections,
    },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    }
  );

  return response.data;
};

export const removeCollectionFromFavorites = async (collection) => {
  let favoritesCollections = localStorage.getItem("favoritesCollections");
  if (favoritesCollections) {
    favoritesCollections = JSON.parse(favoritesCollections);
    const collectionAlreadyInFavorites = verifyIfIsFavorite(
      collection.id,
      "collections"
    );
    if (collectionAlreadyInFavorites) {
      const newFavoritesCollections = favoritesCollections.filter(
        (favorite) => favorite.id !== collection.id
      );
      localStorage.setItem(
        "favoritesCollections",
        JSON.stringify(newFavoritesCollections)
      );

      const audios = await handleLocalStorageData("favoritesAudios");

      const response = await axios.put(
        `${config.coreApiUrl}/likes`,
        {
          audios,
          collections: newFavoritesCollections,
        },
        {
          headers: {
            Authorization: getAccessToken(),
          },
        }
      );

      return response.data;
    }
  } else {
    return;
  }
};

export const handleLocalStorageData = (data) => {
  let favoriteCollections = localStorage.getItem(data);
  if (!favoriteCollections) {
    favoriteCollections = [];
  } else {
    favoriteCollections = JSON.parse(favoriteCollections);
  }

  return favoriteCollections;
};
