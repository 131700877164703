import axios from "axios";
import { notify } from "../App";

import { config } from "../config";
import { getAccessToken } from "./token";
import { sendPlaylist } from "../utils/mobile";
import { notifyBugsnag } from "../utils/BugsnagService";

export const getMyPlaylists = async () => {
  const lastRefresh = localStorage.getItem("lastPlaylistsRefresh");
  const now = new Date().getTime();
  const twentyFourHours = 24 * 60 * 60 * 1000;

  if ((lastRefresh && now - lastRefresh > twentyFourHours) || !lastRefresh) {
    try {
      const response = await axios.get(`${config.coreApiUrl}/playlists`, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      let playlists = response.data;
      playlists = playlists.map((playlist) => {
        if (!playlist.audios) {
          playlist.audios = [];
        }
        return playlist;
      });

      localStorage.setItem("playlists", JSON.stringify(playlists));
      localStorage.setItem("lastPlaylistsRefresh", now);
      sendPlaylist();
    } catch (error) {
      console.log(error);
      notifyBugsnag(error);
    }
  }
};

export const getPlaylist = async (id) => {
  try {
    const response = await axios.get(`${config.coreApiUrl}/playlists/${id}`, {
      headers: {
        Authorization: getAccessToken(),
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    notifyBugsnag(error);
  }
};

export const createPlaylist = async (title, audios) => {
  try {
    const response = await axios.post(
      `${config.coreApiUrl}/playlists`,

      {
        title,
        audios,
      },
      {
        headers: {
          Authorization: getAccessToken(),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    notifyBugsnag(error);
  }
};

export const addAudioToPlaylist = async (playlist, audio) => {
  let playlists = localStorage.getItem("playlists");
  let alreadyInPlaylist = false;
  if (playlists) {
    playlists = JSON.parse(playlists);
    playlists = playlists.map((item) => {
      if (item.id === playlist.id) {
        if (!item.audios) {
          item.audios = [];
        }

        alreadyInPlaylist = item.audios.find((item) => item.id === audio.id);

        if (alreadyInPlaylist) {
          notify("Ya está en la playlist");
          return item;
        } else {
          item.audios.push(audio);
          notify(`Añadido a ${playlist.title}`);
        }
      }
      return item;
    });
  }
  if (!alreadyInPlaylist) {
    localStorage.setItem("playlists", JSON.stringify(playlists));
    try {
      await axios.put(
        `${config.coreApiUrl}/playlists/${playlist.id}`,
        {
          title: playlist.title,
          audios: [...playlist.audios, audio],
        },
        {
          headers: {
            Authorization: getAccessToken(),
          },
        }
      );
    } catch (error) {
      console.log(error);
      notifyBugsnag(error);
    }
  }
};

export const getPlaylistFromLocalStorage = (id) => {
  let playlists = localStorage.getItem("playlists");
  if (playlists) {
    playlists = JSON.parse(playlists);
    return playlists.find((item) => item.id === id);
  }
};

export const updatePlaylist = async (playlist) => {
  try {
    await axios.put(
      `${config.coreApiUrl}/playlists/${playlist.id}`,
      {
        title: playlist.title,
        audios: playlist.audios,
      },
      {
        headers: {
          Authorization: getAccessToken(),
        },
      }
    );
  } catch (error) {
    console.log(error);
    notifyBugsnag(error);
  }
};

export const updatePlaylistFromLocalStorage = (playlist) => {
  console.log("updatePlaylistFromLocalStorage");
  let playlists = localStorage.getItem("playlists");
  if (playlists) {
    playlists = JSON.parse(playlists);
    // mantain old values but update title and audios
    playlists = playlists.map((item) => {
      if (item.id === playlist.id) {
        item.title = playlist.title;
        item.audios = playlist.audios;
      }
      console.log(item);
      return item;
    });
    localStorage.setItem("playlists", JSON.stringify(playlists));
  }
};

export const deletePlaylist = async (id) => {
  try {
    await axios.delete(`${config.coreApiUrl}/playlists/${id}`, {
      headers: {
        Authorization: getAccessToken(),
      },
    });

    let playlists = localStorage.getItem("playlists");
    if (playlists) {
      playlists = JSON.parse(playlists);
      playlists = playlists.filter((item) => item.id !== id);
      localStorage.setItem("playlists", JSON.stringify(playlists));
    }
    sendPlaylist();
  } catch (error) {
    console.log(error);
    notifyBugsnag(error);
  }
};

export const deleteAudioFromPlaylistFromLocalStorage = (playlist, audio) => {
  let playlists = localStorage.getItem("playlists");
  if (playlists) {
    playlists = JSON.parse(playlists);
    playlists = playlists.map((item) => {
      if (item.id === playlist.id) {
        item.audios = item.audios.filter((item) => item.id !== audio.id);
      }
      return item;
    });
    localStorage.setItem("playlists", JSON.stringify(playlists));
    sendPlaylist();
  }
};
