import React from "react";
import { useNavigate } from "react-router-dom";

import { Capitalize, CapitalizeAll } from "../../utils/strings";
import "./DiscoverWidget.css";

export default function DiscoverWidget(props) {
  const { tagName, bgColor, isPastor, img, collectionId } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    if (isPastor) {
      navigate(`/collection/${collectionId}`);
      return;
    }
    navigate(`/tag/${tagName}`);
  };

  return (
    <div
      className="discover-widget"
      id={isPastor ? "pastor" : ""}
      style={{ backgroundColor: bgColor }}
      onClick={handleClick}
    >
      <p>{isPastor ? CapitalizeAll(tagName) : Capitalize(tagName)}</p>
      {img && <img src={img} alt="discover-widget" />}
    </div>
  );
}
