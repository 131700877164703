import { config } from "../config";
import { getAccessToken, getRefreshToken } from "./token";
import { notifyBugsnag } from "../utils/BugsnagService";

export const loginUser = async (user_login, password) => {
  const response = await fetch(`${config.authApi}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_login,
      password,
    }),
  });

  return response.json();
};

export const logoutUser = async () => {
  const response = await fetch(`${config.authApi}/auth/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });

  return response.json();
};

export const getNewAccessToken = async () => {
  try {
    const response = await fetch(`${config.authApi}/auth/refresh`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: getRefreshToken(),
      },
    });

    return response.json();
  } catch (error) {
    console.log(error);
    notifyBugsnag(error);
  }
  return null;
};

export const verifyActivity = async () => {
  const response = await fetch(`${config.authApi}/auth/verify`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });

  return response.status;
};
