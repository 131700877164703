import React, { useState } from "react";
import "../Share/Share.css";
import "./UserOptions.css";
import { Avatar, ClickAwayListener } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import BugReportIcon from "@mui/icons-material/BugReport";
import LogoutIcon from "@mui/icons-material/Logout";

import pkgjson from "../../../package.json";
import ProblemModal from "./Modal/ProblemModal";
import { readAvatar } from "../../api/user";
import { config } from "../../config";
import { sendExternalLink } from "../../utils/mobile";

export default function UserOptions(props) {
  const { username } = props;
  const { logout } = props;
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { whatsAppUrl } = config;

  const name = username ? username.split(" ") : null;
  const avatar = readAvatar();
  const appVersion = localStorage.getItem("appVersion");

  const validExtension = () => {
    if (!avatar) return false;
    const extension = avatar.split(".").pop();
    return ["jpg", "jpeg", "png"].includes(extension);
  };

  const openChat = () => {
    if (!localStorage.getItem("externalLinks")) {
      window.open(whatsAppUrl, "_blank");
    } else {
      sendExternalLink(whatsAppUrl);
    }
    setOpen(false);
  };

  const avatarStyle = {
    width: 30,
    height: 30,
    backgroundColor: "primary.contrast",
    color: "white",
    marginLeft: "0.5rem",
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="Share">
        {validExtension() && (
          <Avatar
            className="ShareAvatar"
            sx={avatarStyle}
            onClick={() => setOpen(!open)}
            src={avatar}
          ></Avatar>
        )}
        {!validExtension() && (
          <Avatar
            className="ShareAvatar"
            sx={avatarStyle}
            onClick={() => setOpen(!open)}
          >
            {username ? username[0].toUpperCase() : null}
          </Avatar>
        )}

        {open && (
          <div className="UserOptions">
            <div className="Username">
              <p className="ShareMenuItemText">{name[0]}</p>
            </div>
            <div className="ShareMenuItem" onClick={openChat}>
              <WhatsAppIcon className="ShareMenuItemIcon" />
              <p className="ShareMenuItemText">Solicitar ayuda</p>
            </div>
            <div
              className="ShareMenuItem"
              onClick={() => setOpenModal(true) + setOpen(false)}
            >
              <BugReportIcon className="ShareMenuItemIcon" />
              <p className="ShareMenuItemText">Reportar problema</p>
            </div>
            <div className="ShareMenuItem" onClick={logout}>
              <LogoutIcon className="ShareMenuItemIcon" />
              <p className="ShareMenuItemText">Cerrar sesión</p>
            </div>
            <p className="version">Rhema v{pkgjson.version}</p>
            {appVersion && <p className="version">Rhema app v{appVersion}</p>}
          </div>
        )}
        <ProblemModal open={openModal} setOpen={setOpenModal} />
      </div>
    </ClickAwayListener>
  );
}
