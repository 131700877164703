export const getRecentActivity = () => {
  const recent = localStorage.getItem("recent");
  if (recent) {
    return JSON.parse(recent);
  }

  return [];
};

export const addRecentActivity = (activity) => {
  const recent = getRecentActivity();

  // Check if the activity is already in the list by comparing the id and removing it
  const index = recent.findIndex((item) => item.id === activity.id);
  if (index !== -1) {
    recent.splice(index, 1);
  }

  if (recent.length === 10) {
    recent.shift(); // remove oldest activity
    recent.push(activity); // add new activity
  } else {
    recent.push(activity);
  }

  localStorage.setItem("recent", JSON.stringify(recent));
};
