import "./Lyrics.css";
import { Drawer } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getAuthorsString } from "../../../api/audios";
import { Close } from "@mui/icons-material";

export default function Lyrics(props) {
  const { audio, open, setOpen } = props;
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      variant="persistent"
      sx={{ display: open ? "block" : "none" }}
    >
      <Box
        sx={{
          width: "95vw",
          height: "max-content",
          backgroundColor: audio.color,
          filter: "brightness(0.9)",
          overflow: "auto",
          padding: "10px",
        }}
        role="presentation"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
      >
        <div className="lyricsHeader">
          <div className="lyricsImg">
            <img src={audio.thumbnail} alt="thumbnail" />
          </div>
          <div className="lyricsData">
            <h3>{audio.title}</h3>
            <p>{getAuthorsString(audio.authors)}</p>
          </div>
          <Close onClick={() => setOpen(false)} />
        </div>
        <p style={{ whiteSpace: "pre-line", fontSize: "15px" }}>
          {audio.lyrics}
        </p>
      </Box>
    </Drawer>
  );
}
