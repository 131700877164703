import axios from "axios";

import { config } from "../config";
import { getAccessToken } from "./token";

export const getHome = async () => {
  const lastHomeRefresh = localStorage.getItem("lastHomeRefresh");
  const halfHour = 1000 * 60 * 30;

  if (
    localStorage.getItem("home") &&
    lastHomeRefresh &&
    Date.now() - lastHomeRefresh < halfHour
  ) {
    return JSON.parse(localStorage.getItem("home"));
  }

  const response = await axios.get(`${config.coreApiUrl}/audios/home`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });

  localStorage.setItem("home", JSON.stringify(response.data));
  localStorage.setItem("lastHomeRefresh", Date.now());

  return response.data;
};

export const getAudio = (id) => {
  return axios.get(`${config.coreApiUrl}/audios/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};

export const getRandomAudio = () => {
  return axios.get(config.coreApiUrl + "/audios/random", {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};

export const getRadioStation = () => {
  return axios.get(config.coreApiUrl + "/audios/radio", {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};

export const getAudiosByTag = (tag) => {
  return axios.get(config.coreApiUrl + "/audios/tag/" + tag, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};

export const getAllTitles = async () => {
  const lastRefresh = localStorage.getItem("lastTitlesRefresh");
  const now = Date.now();
  const halfHour = 1000 * 60 * 30;
  if (
    localStorage.getItem("allTitles") &&
    lastRefresh &&
    now - lastRefresh < halfHour
  ) {
    return JSON.parse(localStorage.getItem("allTitles"));
  } else {
    const response = await axios.get(config.coreApiUrl + "/audios/all", {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAccessToken(),
      },
    });

    const audios = response.data.map((audio) => {
      return {
        id: audio.id,
        label: audio.title,
      };
    });

    const collResponse = await axios.get(
      config.coreApiUrl + "/collections/all",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAccessToken(),
        },
      }
    );

    const collections = collResponse.data.map((coll) => {
      return {
        id: coll.id,
        label: coll.title,
      };
    });

    const all = [...audios, ...collections];

    const uniqueResources = all.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.label === thing.label)
    );

    localStorage.setItem("allTitles", JSON.stringify(uniqueResources));
    localStorage.setItem("lastTitlesRefresh", now);
  }
};

export const getAllTitlesFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("allTitles"));
};

export const getAuthorsString = (authors) => {
  let string = "";
  if (authors) {
    if (authors.length === 1) {
      string = authors[0].name;
    }

    if (authors.length === 2) {
      if (authors[1].name[0] === "I") {
        string = authors[0].name + " e " + authors[1].name;
      } else {
        string = authors[0].name + " y " + authors[1].name;
      }
    }

    if (authors.length > 2) {
      for (let i = 0; i < authors.length; i++) {
        if (i === authors.length - 1) {
          string += " y " + authors[i].name;
        } else if (i === authors.length - 2) {
          string += authors[i].name;
        } else {
          string += authors[i].name + ", ";
        }
      }
    }
  }

  return string;
};

export const getReadPresignedUrl = async (key) => {
  const res = await axios.get(`${config.coreApiUrl}/audios/read/${key}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });

  return res.data;
};
