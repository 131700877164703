import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { getAccessToken } from "../../api/token";
import AuthForm from "../../components/AuthForm/AuthForm";

export default function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getAccessToken();
    if (token) {
      navigate("/");
    }
  });

  return (
    <div className="View Login">
      <AuthForm type="login" />
    </div>
  );
}
