import axios from "axios";

import { config } from "../config";
import { getAccessToken } from "./token";

export const getCollections = (filter = 1) => {
  return axios.get(config.coreApiUrl + "/collections?filter=" + filter, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};

export const getCollection = (id) => {
  return axios.get(config.coreApiUrl + "/collections/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};
