import jwtDecode from "jwt-decode";

export const setAccessToken = (token) => {
  localStorage.setItem("access_token", token);
};

export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const removeAccessToken = () => {
  localStorage.removeItem("access_token");
};

export const setRefreshToken = (token) => {
  localStorage.setItem("rt", token);
};

export const getRefreshToken = () => {
  return localStorage.getItem("rt");
};

export const removeRefreshToken = () => {
  localStorage.removeItem("rt");
};

export const isValidToken = (token) => {
  let decode;
  try {
    decode = jwtDecode(token);
    const expire = decode.exp * 1000;
    const currentTime = new Date().getTime();

    if (currentTime < expire) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};
