import React from "react";
import "./Playlists.css";
import { useNavigate } from "react-router-dom";

import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import { getElementsQuantity } from "../../utils/strings";

export default function Playlists(props) {
  const { playlists, addToPlaylist, audio, origin } = props;
  const navigate = useNavigate();

  const action = (playlist) => {
    if (origin === "player") {
      addToPlaylist(playlist, audio);
    } else {
      navigate(`/playlist/${playlist.id}`);
    }
  };

  const validatePlaylist = (playlist) => {
    if (playlist.audios.length > 0) {
      return <img className="playlist-img" src={playlist.audios[0].thumbnail} alt = "playlist"/>
    } else {
      return <PlaylistPlayIcon  sx={{ fontSize: 40 }} />;
    }
  };

  return (
    <div>
      {playlists.length > 0 ? (
        <div className="playlists">
          <div className="playlists-container">
            {playlists.map((playlist) => (
              <div
                className="playlist"
                key={playlist.id}
                onClick={() => action(playlist)}
              >
                <div className="playlist-img">
                  {validatePlaylist(playlist)}
                </div>
                <div className="playlist-info">
                  <h3>{playlist.title}</h3>
                  <p>
                    {origin === "player" ? "" : "Playlist - "}
                    {getElementsQuantity(playlist.audios)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
