import React, { useEffect, useState } from "react";
import "./Playlist.css";
import "../Collection/Collection.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MoreVert, PlayCircle, Share } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { share } from "../../utils/progressive";
import {
  getPlaylist,
  getPlaylistFromLocalStorage,
  updatePlaylist,
  updatePlaylistFromLocalStorage,
} from "../../api/playlists";
import CollectionItem from "../../components/CollectionItem/CollectionItem";
import usePlayer from "../../hooks/usePlayer";
import PlaylistDrawer from "./Drawer/Drawer";
import { Backdrop, CircularProgress } from "@mui/material";
import CreatePlaylistDialog from "../../components/Modals/CreatePlaylistDialog/CreatePlaylistDialog";
import ItemDrawer from "./ItemDrawer/ItemDrawer";
import useAuth from "../../hooks/useAuth";
import getActiveItemClass from "../../utils/getItemClass";
import { notifyBugsnag } from "../../utils/BugsnagService";

export default function Playlist() {
  const id = window.location.pathname.split("/")[2];
  const navigate = useNavigate();
  const [playlist, setPlaylist] = useState(getPlaylistFromLocalStorage(id));
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    audio: currentAudio,
    playingACollection,
    setPlayingACollection,
    setCollection,
    setIsPlaying,
    setAudio,
    setShowPlayer,
    setCollectionId,
  } = usePlayer();
  const [openPlaylisDrawer, setOpenPlaylisDrawer] = useState(false);
  const [openEditPlaylist, setOpenEditPlaylist] = useState(false);
  const [openItemOptions, setOpenItemOptions] = useState(false);
  const [optionItem, setOptionItem] = useState(null);
  const [playlistItems, setPlaylistItems] = useState([]);
  const { auth } = useAuth();

  /* eslint-disable */
  useEffect(() => {
    window.scrollTo(0, 0);

    const playlist = getPlaylistFromLocalStorage(id);
    if (playlist !== null && playlist !== undefined) {
      setPlaylist(playlist);
      setPlaylistItems(playlist.audios);
    } else {
      try {
        getPlaylist(id).then((playlist) => {
          setPlaylist(playlist);
          setPlaylistItems(playlist.audios);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);  
        notifyBugsnag(error);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    setPlaylist(getPlaylistFromLocalStorage(id));
  }, [refresh]);
  /* eslint-enable */

  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    }

    navigate("/library");
  };

  const playPlaylist = () => {
    localStorage.setItem("tracktimePosition", 0);
    setCollection(playlist.audios);
    setPlayingACollection(true);
    setCollectionId(playlist.id);
    setAudio(playlist.audios[0]);
    setIsPlaying(true);
    setShowPlayer(true);
  };

  const playItem = (item) => {
    localStorage.setItem("tracktimePosition", 0);
    if (!playingACollection) {
      setCollection(playlist.audios);
      setPlayingACollection(true);
      setCollectionId(playlist.id);
    }
    setAudio(item);
    setIsPlaying(true);
    setShowPlayer(true);
  };

  const onDragEnd = (result) => {
    const items = Array.from(playlistItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPlaylistItems(items);
    handleSortUpdate(items);
  };

  const handleSortUpdate = (items) => {
    const playlist = getPlaylistFromLocalStorage(id);
    playlist.audios = items;
    updatePlaylistFromLocalStorage(playlist);
    updatePlaylist(playlist);
  };

  return (
    <div className="View Collection">
      <div
        className="ViewHeader CollHeader"
        style={{
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="LinkArrowBack" onClick={() => goBack()}>
          <ArrowBackIcon sx={{ fontSize: 30 }} />
        </div>
        {auth && playlist && auth.email !== playlist.user_email && (
          <div onClick={() => share(playlist, "playlist")} className="Share">
            <Share sx={{ fontSize: 30 }} />
          </div>
        )}
      </div>
      {!loading && playlist && playlist.audios.length > 0 ? (
        <div className="CollectionContent">
          <div className="PlaylistInfo">
            <div className="PlaylistImg">
              <img
                className="PlaylistImgContainer"
                src={playlist.audios[0].thumbnail}
                alt="playlist"
              />
            </div>
            <div className="PlaylistData">
              <div className="PlaylistName">
                <h3>{playlist.title}</h3>
                {auth && auth.email !== playlist.user_email && (
                  <p>de {playlist.user_display_name}</p>
                )}
              </div>
              <div className="PlaylistOptions">
                {auth && auth.email === playlist.user_email ? (
                  <div
                    className="Option"
                    onClick={() => share(playlist, "playlist")}
                  >
                    <Share sx={{ fontSize: 30, marginRight: "22px" }} />
                  </div>
                ) : null}
                <div className="Option" onClick={() => playPlaylist()}>
                  <PlayCircle sx={{ fontSize: 80 }} />
                </div>
                {auth && auth.email === playlist.user_email ? (
                  <div
                    className="Option"
                    onClick={() => setOpenPlaylisDrawer(true)}
                  >
                    <MoreVert sx={{ fontSize: 30, marginLeft: "20px" }} />
                  </div>
                ) : null}
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="CollectionContents">
                  <Droppable droppableId="playlist">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {playlistItems.map((audio, index) => (
                          <Draggable
                            key={audio.id}
                            draggableId={audio.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={getActiveItemClass(
                                  audio.id,
                                  currentAudio,
                                  "PlaylistItem"
                                )}
                                onClick={() => playItem(audio)}
                                key={index}
                              >
                                <CollectionItem key={index} item={audio} />
                                {auth && auth.email === playlist.user_email ? (
                                  <div
                                    className="PlaylistItemOptions"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpenItemOptions(true);
                                      setOptionItem(audio);
                                    }}
                                  >
                                    <MoreVert sx={{ fontSize: 30 }} />
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
          </div>
          <Backdrop
            open={openPlaylisDrawer}
            onClick={() => setOpenPlaylisDrawer(false)}
            sx={{
              zIndex: 3000,
            }}
          >
            <PlaylistDrawer
              open={openPlaylisDrawer}
              setOpen={setOpenPlaylisDrawer}
              setOpenEditPlaylist={setOpenEditPlaylist}
              item={{ id: playlist.id }}
            />
          </Backdrop>
          <Backdrop
            open={openItemOptions}
            onClick={() => setOpenItemOptions(false)}
            sx={{
              zIndex: 3000,
            }}
          >
            <ItemDrawer
              open={openItemOptions}
              setOpen={setOpenItemOptions}
              playlist={{
                id: playlist.id,
                title: playlist.title,
                audios: playlist.audios,
              }}
              optionItem={optionItem}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Backdrop>
          <CreatePlaylistDialog
            open={openEditPlaylist}
            setOpen={setOpenEditPlaylist}
            isEdit={true}
            item={{
              id: playlist.id,
              title: playlist.title,
              audios: playlist.audios,
            }}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </div>
      ) : (
        <div className="EmptyPlaylist">
          {loading ? <CircularProgress /> : <p>La playlist está vacía</p>}
        </div>
      )}
    </div>
  );
}
