import React, { useState, useEffect } from "react";
import "./CreatePlaylistDialog.css";
import { Modal, Box, TextField, Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";

import {
  createPlaylist,
  getMyPlaylists,
  updatePlaylist,
  updatePlaylistFromLocalStorage,
} from "../../../api/playlists";
import { handleLocalStorageData } from "../../../api/favorites";
import { notify } from "../../../App";
import { hideNavBarAndPlayer, showNavBarAndPlayer } from "../../../utils/ux";

export default function CreatePlaylistDialog(props) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const {
    open,
    setOpen,
    setPlaylists,
    setOpenSuccess,
    isEdit,
    item,
    refresh,
    setRefresh,
  } = props;
  const [loading, setLoading] = useState(false);

  const resize = () => {
    setScreenWidth(window.innerWidth);
  };

  /* eslint-disable */
  useEffect(() => {
    if (isEdit) {
      formik.setFieldValue("title", item.title);
    }
  }, [open]);
  /* eslint-enable */

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      title: "",
    },

    validate: (values) => {
      const errors = {};
      if (!values.title) {
        errors.title = "El título es requerido";
      }

      return errors;
    },

    onSubmit: async (values) => {
      setLoading(true);
      if (isEdit) {
        await updatePlaylist({
          id: item.id,
          title: values.title,
          audios: item.audios,
        });

        updatePlaylistFromLocalStorage({
          id: item.id,
          title: values.title,
          audios: item.audios,
        });
        notify("Playlist editada");
        setRefresh(!refresh);
      } else {
        await createPlaylist(
          values.title,
          localStorage.getItem("selectedAudio")
            ? [JSON.parse(localStorage.getItem("selectedAudio"))]
            : []
        );
      }
      setLoading(false);
      setOpen(false);
      setOpenSuccess(true);
      formik.resetForm();
      localStorage.removeItem("lastPlaylistsRefresh");
      await getMyPlaylists();
      setPlaylists(handleLocalStorageData("playlists"));
      localStorage.removeItem("selectedAudio");
    },
  });
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "#161833",
          width: window.innerWidth > 570 ? "500px" : "90%",
          height: "200px",
          margin: "auto",
          marginTop: screenWidth > 570 ? "15%" : "40%",
          padding: "1px 10px 0px 10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <h3>{isEdit ? "Editar" : "Crear"} playlist</h3>
          <TextField
            id="outlined-basic"
            label="Título"
            variant="outlined"
            sx={{ width: "100%" }}
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            onFocus={() => hideNavBarAndPlayer()}
            onBlur={() => showNavBarAndPlayer()}
          />
          <div className="createPlaylistButtons">
            <Button
              type="button"
              sx={{
                backgroundColor: "#161833",
                color: "white",
                "&:hover": {
                  backgroundColor: "#161833",
                },
              }}
              onMouseDown={() => setOpen(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              onMouseDown={() => formik.handleSubmit()}
              disabled={loading || formik.values.title === item?.title}
              variant="contained"
              sx={{
                marginLeft: "20px",
              }}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ color: "primary.main" }} />
              ) : isEdit ? (
                "Listo"
              ) : (
                "Crear"
              )}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
