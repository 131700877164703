import { createContext } from "react";

const PlayerContext = createContext({
  showPlayer: false,
  setShowPlayer: () => {},

  audio: {},
  setAudio: () => {},

  isPlaying: false,
  setIsPlaying: () => {},

  queue: [],
  setQueue: () => {},

  playingACollection: false,
  setPlayingACollection: () => {},

  collection: {},
  setCollection: () => {},

  collectionId: "",
  setCollectionId: () => {},
});

export default PlayerContext;
